import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import arrowUp from 'assets/svg/arrow_up.svg';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  position: 'unset',
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  position: 'unset',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  position: 'unset',
  padding: 'auto 40px',
  borderRadius: '0px 0px 12px 12px',
}));

export default function CustomizedAccordion({
  isExpanded,
  expandMe,
  hideMe,
  title,
  listLength,
  node,
  onSelect = () => ({}),
  showHorizontalLine = false,
}: {
  isExpanded: boolean;
  expandMe: Function;
  hideMe: Function;
  title: string;
  listLength?: number;
  node: React.ReactNode;
  onSelect?: Function;
  showHorizontalLine: boolean;
}) {
  return (
    //   TODO: Change all inline stylings to class based.
    <Accordion
      expanded={isExpanded}
      onChange={(_, expanded) => {
        if (expanded) {
          expandMe();
          onSelect();
        } else {
          hideMe();
        }
      }}
      sx={{
        margin: '0px 0px 20px',
        maxWidth: '100%',
        border: isExpanded ? '2px solid #ffc632' : '0',
        borderRadius: '12px',
        backgroundColor: isExpanded ? '#FCFCFC' : '#f3f4f5',
        boxShadow: isExpanded ? '0px 0px 8px rgba(97, 94, 87, 0.14)' : 'none',
      }}
    >
      <AccordionSummary
        aria-controls='panel1d-content'
        id='panel1d-header'
        expandIcon={
          <div>
            <img src={arrowUp} alt='Arrow Up' style={{ height: '8px' }} />
          </div>
        }
        sx={{ borderRadius: isExpanded ? '12px 12px 0px 0px' : '12px' }}
      >
        <div
          style={{
            width: '95%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography className='font-semi-bold'>{title}</Typography>
          <Typography
            style={{
              position: 'relative',
              right: '-10px',
              top: '2px',
              color: 'rgba(16, 24, 40, 0.44)',
            }}
          >
            {listLength}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {showHorizontalLine && (
          <div style={{ width: '100%', background: '#E5E7EA', height: '2px' }}></div>
        )}
        {node}
      </AccordionDetails>
    </Accordion>
  );
}
