import userSaga from 'store/user/authSaga';
import profileSaga, { contentSaga } from 'store/user/profileSaga';
import paymentsSaga from "store/payments/paymentsSaga";
import { all, takeLatest } from 'redux-saga/effects';
import store from '.';

export default function* rootSaga() {
  yield all([initSaga(), userSaga(), profileSaga(), contentSaga(), paymentsSaga()]);
}

function* initSagaHandler() {
  yield store.dispatch({ type: 'INIT_AUTH' });
}

function* initSaga() {
  yield takeLatest('INIT_SAGA_HANDLER', initSagaHandler);
}
