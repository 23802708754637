import { HTMLInputTypes, InputProps } from 'utils/types';
import { checkForErrors } from '../formUtils';
import { breakpoints } from 'styles/Breakpoints';

import styled from 'styled-components';
// import { useEffect } from 'react';

export const InputWrapper = styled.div`
  input {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    background-color: rgba(71, 84, 103, 0.05);
    // background-color: black;


    width: 100%;
    height: 48px;
    border-radius: 12px;
    border: 0;
    font-size: 16px;

    padding-left: 10px;
    font-family: Nunito Sans, sans-serif;

    :focus {
      outline: 1;
    }

    // :invalid {
    //   border: 2px solid red;
    // }

    .invalidminor {
      border: 2px solid red;
    }

    :valid {
      border: 0px solid;
    }

    :placeholder-shown {
      border: 0;
    }

    /* for input type 'date' */
    ::-webkit-calendar-picker-indicator {
      cursor: pointer;
      margin-right: 10px;
      color: #000000;
    }
    ::-webkit-date-and-time-value {
      text-align: left;
    }
    -webkit-appearance: none;
    color: #000000;
  }

  .errorMessage {
    margin: 10px 0px;
    color: #ba1b1b;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    font-size: 16px;
    .errorMessage {
      font-size: 12px;
      color: #ea6d2b;
      margin-top: 5px;
      font-weight: 600;
    }
  }
`;

const InputField = ({
  type,
  register,
  errors,
  errorMessage,
  fieldToRegister,
  placeHolder,
  required = false,
  pattern,
  maxDate,
  minorValid,
  disabled,
  getValues,
  watch,
  onChangeValue,
  useFormRegisterConfig,
  defaultValue,
  readOnly,
}: InputProps) => {
  const registerConfig = {
    required,
    ...(useFormRegisterConfig || {}),
    ...(pattern ? { pattern } : {}), // Uses pattern only if any valid regex is passed as a prop.
  };

  const validField = watch && type === HTMLInputTypes.DATE && watch(fieldToRegister);
const {onChange: formOnChange, ...rest} = register(fieldToRegister, registerConfig);

  return (
    <InputWrapper>
      <input
        id='myInput'
        // {...register(fieldToRegister, registerConfig)}
        {...rest}
        readOnly={readOnly}
        className={` ${disabled && 'cursor-not-allowed'} `}
        disabled={disabled}
        placeholder={required ? `${placeHolder}*` : placeHolder}
        type={placeHolder && type === HTMLInputTypes.DATE && !defaultValue ? 'text' : type}
        onFocus={(e) =>
          placeHolder && type === HTMLInputTypes.DATE && (e.target.type = HTMLInputTypes.DATE)
        }
        onBlur={(e) =>
          placeHolder && type === HTMLInputTypes.DATE && e.target.value
            ? (e.target.type = 'date')
            : (e.target.type = 'text')
        }
        // onChange={ (e) => {
        //   if (type === HTMLInputTypes.DATE) {
        //     const { onChange } = register(fieldToRegister, registerConfig);
        //     onChange(e.target.value as any);
        //     if (onChangeValue) onChangeValue(e.target.value);
        //   } else {
        //     const { onChange } = register(fieldToRegister, registerConfig);
        //     onChange(e.target.value as any);
        //   }
        // }}
        onChange={(e) => {
          if (onChangeValue && placeHolder === 'Pin Code') {
            onChangeValue(e.target.value);
          }
          formOnChange(e)

        }}
        max={maxDate ? maxDate : ''}
        style={{
          border: validField ? '0' : type === HTMLInputTypes.DATE ? '0' : '',
        }}
        {...{ ...(defaultValue ? { defaultValue } : {}) }}
      />

      {errors && Object.keys(errors).length > 0 && checkForErrors({ fieldToRegister, errors }) && (
        <p className='errorMessage'>{errorMessage}</p>
      )}
    </InputWrapper>
  );
};

export default InputField;
