import { createSlice, createSelector, PayloadAction, createAction } from "@reduxjs/toolkit";
import { MemberDetails } from "Pages/NewPayments/MemberDetailsFormModal";
import { RootState } from "store";
import { InitiateCartPaymentResponse, InitiatePaymentResponse } from "utils/paymentSummaryTypes";
import { ConfigResponse, PurchasedPlan } from "./types";

export enum PaymentsPrerequisites {
  LOADING,
  NOT_LOGGED_IN,
  LOGGED_IN,
  NOT_ONBOARDED,
  ONBOARDED,
  DOB_ISNRI_MISMATCH,
  NO_DOB_ISNRI_MISMATCH,
  NO_PLANS_SELECTED,
  PLANS_SELECTED,
  SUCCESS
}

interface PaymentsState {
  preq: PaymentsPrerequisites;
  selectedPlans: string[];
  dob?: string;
  isNri?: boolean;
  pricingResponse?: Partial<InitiateCartPaymentResponse> | Partial<InitiatePaymentResponse>;
  configResponse?: ConfigResponse;
  promoCode?: string;
  purchasedPlans?: PurchasedPlan[];
  preqObj: {
    loggedIn?: boolean;
    onboarded?: boolean;
    noMismatch?: boolean;
    plansSelected?: boolean;
  }
}

const initialState: PaymentsState = {
  preq: PaymentsPrerequisites.LOADING,
  preqObj: {
    loggedIn: undefined,
    onboarded: undefined,
    noMismatch: undefined,
    plansSelected: undefined,
  },
  selectedPlans: [],
}

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    resetState: () => ({ ...initialState }),
    setPurchasedPlans: (state: PaymentsState, action: PayloadAction<PurchasedPlan[]>) => {
      state.purchasedPlans = action.payload;
    },
    setPreRequisites: (state: PaymentsState, action: PayloadAction<PaymentsPrerequisites>) => {
      state.preq = action.payload;

      if (action.payload === PaymentsPrerequisites.NOT_LOGGED_IN) {
        state.preqObj.loggedIn = false;
        return;
      }

      if (action.payload === PaymentsPrerequisites.LOGGED_IN) {
        state.preqObj.loggedIn = true;
        return;
      }

      if (action.payload === PaymentsPrerequisites.NOT_ONBOARDED) {
        state.preqObj.onboarded = false;
        return;
      }

      if (action.payload === PaymentsPrerequisites.ONBOARDED) {
        state.preqObj.onboarded = true;
        return;
      }

      if (action.payload === PaymentsPrerequisites.NO_PLANS_SELECTED) {
        state.preqObj.plansSelected = false;
        return;
      }

      if (action.payload === PaymentsPrerequisites.PLANS_SELECTED) {
        state.preqObj.plansSelected = true;
        return;
      }

      if (action.payload === PaymentsPrerequisites.DOB_ISNRI_MISMATCH) {
        state.preqObj.noMismatch = false;
        return;
      }

      if (action.payload === PaymentsPrerequisites.NO_DOB_ISNRI_MISMATCH) {
        state.preqObj.noMismatch = true;
        return;
      }

      if (action.payload === PaymentsPrerequisites.SUCCESS) {
        state.preqObj.plansSelected = true;
        state.preqObj.loggedIn = true;
        state.preqObj.onboarded = true;
        state.preqObj.noMismatch = true;
        return;
      }

    },
    setDobNri: (state: PaymentsState, action: PayloadAction<{ dob: string, isNri: boolean }>) => {
      const { dob, isNri } = action.payload;
      state.dob = dob;
      state.isNri = isNri;
    },
    setSelectedPlans: (state: PaymentsState, action: PayloadAction<string[]>) => {
      state.selectedPlans = action.payload;
    },
    setPricingResponse: (state: PaymentsState, action: PayloadAction<Partial<InitiateCartPaymentResponse> | Partial<InitiatePaymentResponse>>) => {
      state.pricingResponse = action.payload;
    },
    setConfigResponse: (state: PaymentsState, action: PayloadAction<ConfigResponse>) => {
      state.configResponse = action.payload;
    },
    setPromoCode: (state: PaymentsState, action: PayloadAction<string>) => {
      state.promoCode = action.payload;
    },
    addYessToCart: (state: PaymentsState) => {
      if (state.selectedPlans.length > 0) {
        state.selectedPlans = [...state.selectedPlans, "yess"];
      } else {
        state.selectedPlans = ["yess"];
      }
    }
  }
})

// selectors
const paymentsSliceSelector = (state: RootState) => state.payments || initialState;
export const selectPreRequisites = createSelector([paymentsSliceSelector], (state) => {
  if (
    state.preqObj.loggedIn === undefined &&
    state.preqObj.plansSelected === undefined &&
    state.preqObj.noMismatch === undefined &&
    state.preqObj.onboarded === undefined
  ) return PaymentsPrerequisites.LOADING;

  if (!state.preqObj.onboarded)
    return PaymentsPrerequisites.NOT_ONBOARDED;

  if (!state.preqObj.plansSelected)
    return PaymentsPrerequisites.NO_PLANS_SELECTED;

  if (!state.preqObj.noMismatch)
    return PaymentsPrerequisites.DOB_ISNRI_MISMATCH;

  if (!state.preqObj.loggedIn)
    return PaymentsPrerequisites.NOT_LOGGED_IN;

  return PaymentsPrerequisites.SUCCESS;
});
export const selectDOBNRI = createSelector([paymentsSliceSelector], (state) => ({ dob: state.dob, isNRI: state.isNri }));
export const selectConfig = createSelector([paymentsSliceSelector], (state) => state.configResponse);
export const selectPromoCode = createSelector([paymentsSliceSelector], (state) => state.promoCode);
export const selectCartResponse = createSelector([paymentsSliceSelector], (state) => state.pricingResponse);
export const selectAllPurchasedPlans = createSelector([paymentsSliceSelector], (state) => state.purchasedPlans);
export const selectedPlans = createSelector([paymentsSliceSelector], (state) => {
  if (state.configResponse === undefined || state.selectedPlans.length === 0) return [];
  return state.selectedPlans.map((plan: string) => state.configResponse?.data.fetchPlanByUserInfo.plans.find((pl) => pl.id === plan));
})
export const selectOnboardingFields = createSelector((state: RootState) => state, (state: RootState) => {
  const response: Partial<MemberDetails> = {};
  if (!!state.user.userProfile?.isNRI) {
    response["isNRI"] = state.user.userProfile.isNRI;
  }

  if (state.user.will?.testatorDetails) {
    const { testatorDetails } = state.user.will!;
    response["email"] = testatorDetails!.email || undefined;
    response["fullName"] = testatorDetails!.fullName || undefined;
    response["dateOfBirth"] = testatorDetails!.dateOfBirth || undefined;
  } else {
    response["email"] = state.user.userProfile?.email || undefined;
    response["fullName"] = state.user.userProfile?.fullName || undefined;
    response["dateOfBirth"] = state.user.userProfile?.dateOfBirth || undefined;
  }

  return { ...response, dateOfBirth: response.dateOfBirth?.substring(0, 10) || undefined };
})
export const selectUserHasPlan = createSelector((state: RootState) => state, (state: RootState) => {
  const userHasPlan = state.user.currentUserPlansResponse.data?.fetchPlans.length !== 0;
  return userHasPlan;
});

export const selectPurchasedPlans = createSelector([paymentsSliceSelector], (state: PaymentsState) => {
  const planDetails = [
    {
      id: "starter",
      firstLine: "Starter Will",
      secondLine: "Yellow Will",
      expiryLinePrefix: "Create by"
    },
    {
      id: "pro",
      firstLine: "Pro Will",
      secondLine: "Yellow Will + Two 30 min Legal Expert Calls",
      expiryLinePrefix: "Create by"
    },
    {
      id: "SMART",
      firstLine: "Yellow Smart",
      secondLine: "Annual Subscription : Post Demise Asset Transfer Cover",
      expiryLinePrefix: "Renew it by"
    },
    {
      id: "YESS",
      firstLine: "Yellow SMART Remote Support",
      secondLine: "Annual Subscription : Post Demise Online Support",
      expiryLinePrefix: "Renew it by"
    },
  ];

  return (state.purchasedPlans || [])
    .map((plan: PurchasedPlan) => {
      const details = planDetails.find(pl => pl.id === plan.itemId);
      const initiatedDate = new Date(plan.paymentInitiatedDate);
      const expiryDate = new Date(initiatedDate);
      expiryDate.setFullYear(initiatedDate.getFullYear() + 1);
      expiryDate.setDate(expiryDate.getDate() - 1);

      return {
        ...details,
        startDate: initiatedDate,
        expiry: expiryDate
      };
    });
})

// actions
export const {
  setPricingResponse,
  setSelectedPlans,
  setDobNri,
  setPreRequisites,
  setPurchasedPlans,
  resetState,
  setConfigResponse,
  setPromoCode,
  addYessToCart
} = paymentsSlice.actions;

export const selectPlans = createAction<PayloadAction<string>>('payments/setSelectedPlans');
export const paymentPageLoaded = createAction('payments/pageLoaded');
export const loadConfig = createAction('payments/loadConfig');
export const fetchPlanResponse = createAction('payments/fetchPlanResponse');
// export const configResponse = createAction<PayloadAction<ConfigResponse>>("payments/configResponse");
export const setUserOnboarding = createAction<PayloadAction<Partial<MemberDetails>>>('payments/setOnboarding');
export const initiatePayment = createAction('payments/initiatePayment');
export const loadUsersPlans = createAction('payments/loadUsersPlans');

export default paymentsSlice.reducer;
