import mixpanel from 'mixpanel-browser';
import {
  selectActiveWill,
  selectCreatedAt,
  selectCurrentUserPlans,
  selectUserProfile,
} from '../store/user/selectors';
import merge from 'lodash.merge';
import transform from 'lodash.transform';
import isDate from 'lodash.isdate';
import dateformat from 'dateformat';
import hash from 'object-hash';

const MIXPANEL_PROJECT_TOKEN = import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN || '';

const accumulatedProperies: any = {};
let listener: any = undefined;

mixpanel.init(MIXPANEL_PROJECT_TOKEN, { debug: true, ignore_dnt: true });

const track = async (event: string, properties: any = {}) => {
  await setUserProperties(accumulatedProperies);
  mixpanel.track(event, properties);
};

const setLoggedInUserIdFromReduxState = async () => {
  const module = await import('../store');
  const store = module.default;
  const getSlices = () => {
    let userProfile = selectUserProfile(store.getState());
    let createdAt = selectCreatedAt(store.getState());
    let activeWill = selectActiveWill(store.getState());
    let currentUserPlans = selectCurrentUserPlans(store.getState());
    return { userProfile, createdAt, activeWill, currentUserPlans };
  };

  let currentSlices = getSlices();
  const setupMixpanel = (temp?: any) => {
    if (temp.userProfile) {
      setLoggedInUserId(temp.userProfile?.documentId);
      accumulatedProperies.userId = temp.userProfile?.documentId;
      accumulatedProperies['Gender'] = temp.userProfile?.gender?.toString();
      accumulatedProperies['KYC Type'] = temp.userProfile?.kycDetails?.kycType?.toString() || '';
      accumulatedProperies['KYC Status'] =
        temp.userProfile?.kycDetails?.kycStatus?.toString() || '';
    }
    if (temp.createdAt) {
      accumulatedProperies['Date of User creation'] = new Date(temp.createdAt);
    }
    if (!accumulatedProperies['Gender']) {
      accumulatedProperies['Gender'] = temp.activeWill?.testatorDetails?.gender;
    }
    accumulatedProperies['Number of assets added'] = temp.activeWill?.assets?.length || 0;
    accumulatedProperies['Number of liabilities added'] = temp.activeWill?.liabilities?.length || 0;
    accumulatedProperies['Number of Witness added'] = temp.activeWill?.witnesses?.length || 0;
    accumulatedProperies['Plan'] = temp.currentUserPlans?.data?.fetchPlans?.[0]?.planId || '';
    const userPropertiesToSet = transform(
      accumulatedProperies,
      (result, value, key) => {
        // @ts-ignore
        result[key] = isDate(value) ? dateformat(value, "UTC:yyyy-mm-dd'T'HH:MM:ss") : value;
      },
      {},
    );
    mixpanel.people.set(userPropertiesToSet);
  };

  if (!listener)
    listener = store.subscribe(() => {
      const tempSlices = getSlices();
      if (hash(currentSlices) !== hash(tempSlices)) {
        currentSlices = tempSlices;
        setupMixpanel(tempSlices);
      }
    });

  setupMixpanel(currentSlices);
};

const setLoggedInUserId = (userId: string) => {
  mixpanel.identify(userId);
};

const setUserLoggedOut = () => mixpanel.reset();

const setUserProperties = async (properties: any) => {
  if (accumulatedProperies !== properties) merge(accumulatedProperies, properties);
  await setLoggedInUserIdFromReduxState();
};

export const Mixpanel = {
  track,
  setLoggedInUserId,
  setUserLoggedOut,
  setUserProperties,
};
