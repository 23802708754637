import { selectCurrentUserPlans, selectUserProfile } from '../store/user/selectors';
import { getAge } from './index';
import { UserProfile } from './types';
import isEqual from 'lodash.isequal';
import isBoolean from 'lodash.isboolean';
import { CurrentUserPlansResponse } from './currentUserPlansTypes';
const INTERCOM_APP_ID = 'log0dqq1';
const getIntercomObject = () => window.Intercom;
let unsubscribe: any = undefined;
// for app boot, and for user login
const boot = async (params: any = {}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const module = await import('../store');
  const store = module.default;
  let userProfile = selectUserProfile(store.getState());
  let currentUserPlans = selectCurrentUserPlans(store.getState());
  const instantiateIntercom = (
    withUserProfile: UserProfile | null,
    withCurrentUserPlans: Partial<CurrentUserPlansResponse> | null | undefined,
  ) => {
    try {
      return getIntercomObject()('boot', {
        app_id: INTERCOM_APP_ID,
        ...params,
        ...(urlParams.get('utm_source') ? { utm_source: urlParams.get('utm_source') } : {}),
        ...(urlParams.get('utm_content') ? { utm_content: urlParams.get('utm_content') } : {}),
        ...(urlParams.get('utm_campaign') ? { utm_campaign: urlParams.get('utm_campaign') } : {}),
        ...(urlParams.get('utm_medium') ? { utm_medium: urlParams.get('utm_medium') } : {}),
        ...(urlParams.get('utm_term') ? { utm_term: urlParams.get('utm_term') } : {}),
        ...(withUserProfile && withUserProfile?.email
          ? { email: withUserProfile?.email, Email: withUserProfile?.email }
          : {}),
        ...(withUserProfile && withUserProfile?.fullName
          ? { name: withUserProfile?.fullName, Name: withUserProfile?.fullName }
          : {}),
        ...(withUserProfile && withUserProfile?.phoneNumber
          ? { phone: withUserProfile?.phoneNumber, Phone: withUserProfile?.phoneNumber }
          : {}),
        ...(withUserProfile && withUserProfile?.documentId
          ? { user_id: withUserProfile?.documentId }
          : {}),
        ...(withUserProfile && withUserProfile?.dateOfBirth
          ? { age: getAge(withUserProfile?.dateOfBirth), Age: getAge(withUserProfile?.dateOfBirth) }
          : {}),
        ...(withUserProfile && isBoolean(withUserProfile?.isNRI)
          ? { Resident: withUserProfile?.isNRI ? 'Non-Resident' : 'Indian' }
          : {}),
        ...(withUserProfile ? { webUser: 'True' } : {}),
        ...(withCurrentUserPlans?.data?.fetchPlans?.[0]?.planId
          ? {
              plan: withCurrentUserPlans?.data?.fetchPlans?.[0]?.planId,
              Plan: withCurrentUserPlans?.data?.fetchPlans?.[0]?.planId,
            }
          : {}),
        prod: import.meta.env.VITE_YELLOW_PROD === 'true' ? 'Yes' : 'No',
      });
    } catch (err) {}
  };
  if (!unsubscribe)
    unsubscribe = store.subscribe(() => {
      const innerUserProfile = selectUserProfile(store.getState());
      const innerCurrentUserPlans = selectCurrentUserPlans(store.getState());

      if (
        !isEqual(userProfile, innerUserProfile) ||
        !isEqual(currentUserPlans, innerCurrentUserPlans)
      ) {
        userProfile = innerUserProfile;
        currentUserPlans = innerCurrentUserPlans;
        instantiateIntercom(innerUserProfile, innerCurrentUserPlans);
      }
    });
  return instantiateIntercom(userProfile, currentUserPlans);
};

// when the user logs out
const shutdown = () => getIntercomObject()('shutdown');

const toggleLauncher = (st: boolean) => getIntercomObject()('update', { "hide_default_launcher": !st })

const showLauncher = () => toggleLauncher(true);
const hideLauncher = () => toggleLauncher(false);

// may be called for each page change, and also to update
// any user info
const update = (params: any = {}) => getIntercomObject()('update', { ...params });

// call this to track user events
const track = async (event: string, params: any = {}) => {
  await boot();
  getIntercomObject()('trackEvent', event, params);
};

const hideOverLayAndShowIntercom =
  ({ setShowOverlay = () => null }: { setShowOverlay?: Function } = {}) =>
  () => {
    setShowOverlay(false);
    show();
  };

const show = () => {
  getIntercomObject()('show');
};


function isInCSTiming() {
  const currentISTTime = new Intl.DateTimeFormat('en-GB', {
    hour: "2-digit",
    minute: "numeric",
    timeZone: 'Asia/Kolkata',
  }).format(new Date());

  const [hour, min] = currentISTTime.split(":").map(n => parseInt(n));

  const is9To5 = hour > 8 && hour < 17;
  const isBefore530 = hour === 17 && min < 31;

  return is9To5 || isBefore530;
}

const YELLOW_SESSION_KEY = "yellowSession";
// @ts-ignore
const getCurrentTimeInSec = () => parseInt(new Date().getTime() / 1000).toFixed(0);

function hasSessionExpired() {
  const currentTimeInSec = getCurrentTimeInSec();
  // @ts-ignore
  const previousTimeInSec = localStorage.getItem(YELLOW_SESSION_KEY) || currentTimeInSec - 3600;

  console.log({ currentTimeInSec, previousTimeInSec });
  // @ts-ignore
  return (parseInt(currentTimeInSec) - parseInt(previousTimeInSec)) > 3600;
}

// @ts-ignore
const setYellowSessionInLocalStorage = (offset = 0) => localStorage.setItem(YELLOW_SESSION_KEY, getCurrentTimeInSec() - offset);

function showIntercomOnAllPagesIfSessionExpired() {
  const previousSessionData = localStorage.getItem(YELLOW_SESSION_KEY);
  if (previousSessionData === null) {
    // initialize the session timing to a value greater than 1 hour
    setYellowSessionInLocalStorage(3601);
  }

  console.log("Inside show Intercom");

  if (!hasSessionExpired()) return;
  console.log("Session has not expired");
  if (!isInCSTiming()) return;
  console.log("Inside CS hours");

  // session has expired and we are in CS timing
  setTimeout(() => {
    show();
    setYellowSessionInLocalStorage();
  }, 10000);
}

export const Intercom = {
  boot,
  hideOverLayAndShowIntercom,
  show,
  shutdown,
  update,
  track,
  showLauncher,
  hideLauncher,
  showIntercomOnAllPagesIfSessionExpired,
};
