import { gql } from '@apollo/client';

const MAILCHIMP_SEND_MAIL_TEMPLATE = gql`
  mutation SendEmailTemplate($input: EmailTemplateInput!) {
    sendEmailTemplate(input: $input) {
      id
      email
      status
      rejectReason
    }
  }
`;

const DELETE_ACCOUNT = gql`
  mutation deleteUserProfile($input: DeleteUserProfileInput) {
    deleteUserProfile(input: $input)
  }
`;

const FETCH_LOGGED_OUT_CONFIG = gql`
  query {
    loggedOutAppConfig
  }
`;

const FETCH_LOGGED_OUT_PLANS_CONFIG = gql`
  query FetchPlanByUserInfo($input: UserInfo!){
    fetchPlanByUserInfo(input:$input)
  }
`

const FETCH_PAID_PLANS = gql`
  query{
   getPaidItems{
    itemId
    paymentInitiatedDate
   }
  }
`

const FETCH_USER_PROFILE = gql`
  query {
    me {
      profile {
        documentId
        isWelcomeEmailSent
        fullName
        email
        gender
        phoneNumber
        dateOfBirth
        profilePicture
        isNRI
        externalVendor
        wills {
          role
          will_id
          isActive
        }
        primaryAddress {
          addressLine1
          addressLine2
          city
          state
          pinCode
        }
        mailingAddress {
          addressLine1
          addressLine2
          city
          state
          country
          pinCode
        }
        kycDetails {
          kycType
          kycStatus
          idNumber
          fathersName
          fullName
          dateOfBirth
          idfyIdentifiers {
            idfyRequestId
            idfyTaskId
            idfyGroupId
          }
        }
      }
      userAccessRole {
        role
        permissions
      }
      contacts {
        documentId
        relatedTo
        fullName
        email
        phoneNumber
        dateOfBirth
        anniversaryDate
        gender
        religion
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        relationship
        otherRelationship
        roles
        idType
        idNumber
        secondaryBeneficiary {
          type
          charityExists
          charity {
            theme
            charityDetails {
              organization {
                name
                pointOfContact
                phoneNumber
                website
                instructions
                address {
                  addressLine1
                  addressLine2
                  city
                  state
                  pinCode
                  country
                }
              }
            }
          }
          customSplit {
            distribution {
              beneficiaryId
              beneficiaryName
              relationship
              percentageShare
            }
          }
        }
      }
      remainingProfileFields
      will {
        isWillUpdated
        documentId
        dateOfCreation
        willCompletionProgress
        isAmongFirstCustomer
        isPrintCourierRequestPaid
        isFinalWillGenerated
        dateOfSignature
        will_progress
        type
        testatorDetails {
          userId
          fullName
          fatherName
          dateOfBirth
          phoneNumber
          email
          gender
          address {
            addressLine1
            addressLine2
            city
            state
            pinCode
            country
          }
          religion
          anniversaryDate
        }
        organizations {
          name
          pointOfContact
          phoneNumber
          website
          instructions
          address {
            addressLine1
            addressLine2
            city
            state
            pinCode
            country
          }
        }
        assetDistribution {
          assetId
          distribution {
            beneficiaryId
            beneficiaryName
            relationship
            percentageShare
          }
        }
        residuaryDistribution {
          distribution {
            beneficiaryId
            beneficiaryName
            relationship
            percentageShare
          }
        }
        assetTypes {
          BUSINESS
          FINANCIAL
          IMMOVABLE_PROPERTY
          OTHER
        }
        liabilityTypes {
          LOAN
        }
        assets {
          assetDocuments {
            documentName
            documentUrls
            tags
            currentOwner
            assetType
            assetSubType
            assetDocumentId
          }
          pointOfContact {
            name
            contactNumber
            email
          }
          documentId
          ownershipHistory {
            ownerId
            ownerFrom
            ownerTill
          }
          isClaimed
          propertyName
          surveyNumber
          description
          assetType
          assetSubType
          natureOfHolding
          nameOfFirm
          nameOfCompany
          amount
          bankName
          typeOfBankAccount
          nameOfDP
          dematAccountNumber
          address {
            addressLine1
            addressLine2
            city
            state
            pinCode
            country
          }
          brandName
          paintingName
          websiteName
          profileLink
          details
          regNo
          panCardNumber
          jewelleryItem
          hufName
          hufShare
          name
          yearOfManufature
          percentageOfHolding
          sizeOfProperty
          surveyNumber
          propertySubType
          branchName
          ifscCode
          accountNumber
          institutionName
          folioNumber
          depositoryParticipant
          fdNumber
          policyNumber
          sumAssured
          namePostOffice
          uanNumber
          bankName
          registrationNumber
          model
          brand
          kind
          weight
          artistName
          description
          accountType
          exchangeDetails
          coldWallet
          digitalWallet
          eWalletProvider
          wealthManagerName
          fundName
          brokingAgentName
          certificateNumber
          serviceProviderName
          eiaNumber
          description
          borrowerName
          loanAmount
          interestRate
          contactNumber
          loanDate
          noofUnits
          noofVested
          noofunVested
          employerName
          typeOfSecurity
          hpns
          isinNumber
          typeOfCryptoWallet
          employerId
          dpId
          policyName
          typeOfInsurance
          value
          nav
          noofUnits
          additionalNotes
          nominees {
            fullName
            contactId
          }
          jointHolderName
        }
        liabilities {
          currentOwner
          documentId
          liabilityType
          liabilitySubType
          liabilityDetails {
            detailsOfInstitution
            accountNumber
            loanAmount
            description
          }
        }
        guardians {
          guardian
          children
        }
        backupGuardians
        beneficiaries {
          documentId
          relatedTo
          fullName
          dateOfBirth
          phoneNumber
          email
          gender
          relationship
          otherRelationship
          roles
          address {
            addressLine1
            addressLine2
            city
            state
            pinCode
            country
          }
          religion
          anniversaryDate
        }
        executor
        backupExecutors
        willLocation {
          address {
            addressLine1
            addressLine2
            city
            state
            pinCode
            country
          }
          nameOfProperty
          additionalInstructions
          notifyExecutor
          latLong
        }
        funeralArrangement {
          religion
          howToConduct
          whereToConduct
          additionalInstruction
        }
        paymentInfo {
          paidWill
          gracePeriod
          paymentId
        }
        organizations {
          name
          pointOfContact
          phoneNumber
          website
          instructions
          address {
            addressLine1
            addressLine2
            city
            state
            pinCode
            country
          }
        }
        witnesses
        courierRequestInfo {
          id
          userId
          email
          phoneNumber
          name
          mailingAddress {
            addressLine1
            addressLine2
            city
            state
            pinCode
            country
          }
          dateOfCreation {
            status
            date
            time
            timestamp
          }
          status
          expectedDateOfDelivery
          indiaPostTrackingID
        }
        signingDocuments {
          pictureURLs
          videoURL
          videoUploadedAt
        }
        updatedOn
        penalty
        executorConsent
      }
      assets {
        documentId
        currentOwner
        ownershipHistory {
          ownerId
          ownerFrom
          ownerTill
        }
        isClaimed
        assetType
        assetSubType
        natureOfHolding
        nameOfFirm
        address {
          addressLine1
          city
          state
          pinCode
        }
        percentageOfHolding
        sizeOfProperty
        surveyNumber
        branchName
        accountNumber
        institutionName
        folioNumber
        depositoryParticipant
        fdNumber
        policyNumber
        sumAssured
        namePostOffice
        uanNumber
        bankName
        typeOfCryptoWallet
        ifscCode
        propertySubType
        policyName
        typeOfInsurance
        additionalNotes
        pointOfContact {
          name
          contactNumber
          email
        }
        jointHolderName
      }
      liabilities {
        documentId
        currentOwner
        liabilityType
        liabilitySubType
        liabilityDetails {
          detailsOfInstitution
          accountNumber
          loanAmount
          description
        }
      }
      createdAt
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($input: UserProfileInput!) {
    updateUserProfile(input: $input) {
      profile {
        documentId
        fullName
        gender
        email
        phoneNumber
        dateOfBirth
        isNRI
        partner
        primaryAddress {
          addressLine1
          city
          state
          pinCode
        }
        mailingAddress {
          addressLine1
          city
          state
          pinCode
        }
        kycDetails {
          kycType
          kycStatus
          idNumber
          idfyIdentifiers {
            idfyRequestId
            idfyTaskId
            idfyGroupId
          }
        }
        profilePicture
      }
      remainingProfileFields
    }
  }
`;

const ADD_USER_CONTACT = gql`
  mutation AddUserContact($input: ContactInput!) {
    addContact(input: $input) {
      documentId
      relatedTo
      fullName
      email
      phoneNumber
      dateOfBirth
      anniversaryDate
      gender
      religion
      address {
        addressLine1
        addressLine2
        city
        state
        pinCode
        country
      }
      relationship
      otherRelationship
      roles
      idType
      idNumber
      secondaryBeneficiary {
        type
        charityExists
        charity {
          theme
          charityDetails {
            organization {
              name
              pointOfContact
              phoneNumber
              website
              instructions
              address {
                addressLine1
                addressLine2
                city
                state
                pinCode
                country
              }
            }
          }
        }
        customSplit {
          distribution {
            beneficiaryId
            beneficiaryName
            relationship
            percentageShare
          }
        }
      }
    }
  }
`;

const EDIT_USER_CONTACT = gql`
  mutation EditUserContact($input: ContactInput!) {
    editContact(input: $input) {
      documentId
      relatedTo
      fullName
      email
      phoneNumber
      dateOfBirth
      anniversaryDate
      gender
      religion
      address {
        addressLine1
        addressLine2
        city
        state
        pinCode
        country
      }
      relationship
      otherRelationship
      roles
      idType
      idNumber
    }
  }
`;

const GET_SIGNED_STORAGE_URL = gql`
  query {
    getStorageSignedURL
  }
`;

const DELETE_USER_ASSET = gql`
  mutation deleteAsset($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      isWillUpdated
      documentId
      dateOfCreation
      willCompletionProgress
      dateOfSignature
      will_progress
      type
      testatorDetails {
        userId
        fullName
        fatherName
        dateOfBirth
        phoneNumber
        email
        gender
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        religion
        anniversaryDate
      }
      organizations {
        name
        pointOfContact
        phoneNumber
        website
        instructions
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
      }
      assetDistribution {
        assetId
        distribution {
          beneficiaryId
          beneficiaryName
          relationship
          percentageShare
        }
      }
      residuaryDistribution {
        distribution {
          beneficiaryId
          beneficiaryName
          relationship
          percentageShare
        }
      }
      assetTypes {
        BUSINESS
        FINANCIAL
        IMMOVABLE_PROPERTY
        OTHER
      }
      liabilityTypes {
        LOAN
      }
      assets {
        assetDocuments {
          documentName
          documentUrls
          tags
          currentOwner
          assetType
          assetSubType
          assetDocumentId
        }
        pointOfContact {
          name
          contactNumber
          email
        }
        documentId
        ownershipHistory {
          ownerId
          ownerFrom
          ownerTill
        }
        isClaimed
        propertyName
        surveyNumber
        description
        assetType
        assetSubType
        natureOfHolding
        typeOfBankAccount
        nameOfFirm
        nameOfCompany
        amount
        bankName
        nameOfDP
        dematAccountNumber
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        brandName
        paintingName
        websiteName
        profileLink
        details
        regNo
        panCardNumber
        jewelleryItem
        hufName
        hufShare
        name
        yearOfManufature
        percentageOfHolding
        sizeOfProperty
        surveyNumber
        propertySubType
        branchName
        ifscCode
        accountNumber
        institutionName
        folioNumber
        depositoryParticipant
        fdNumber
        policyNumber
        sumAssured
        namePostOffice
        uanNumber
        bankName
        registrationNumber
        model
        brand
        kind
        weight
        artistName
        description
        accountType
        exchangeDetails
        coldWallet
        digitalWallet
        eWalletProvider
        wealthManagerName
        fundName
        brokingAgentName
        certificateNumber
        serviceProviderName
        eiaNumber
        employerName
        typeOfSecurity
        hpns
        typeOfCryptoWallet
        employerId
        dpId
        policyName
        typeOfInsurance
        additionalNotes
        nominees {
          fullName
          contactId
        }
        jointHolderName
      }
      liabilities {
        currentOwner
        documentId
        liabilityType
        liabilitySubType
        liabilityDetails {
          detailsOfInstitution
          accountNumber
          loanAmount
          description
        }
      }
      guardians {
        guardian
        children
      }
      backupGuardians
      beneficiaries {
        documentId
        relatedTo
        fullName
        dateOfBirth
        phoneNumber
        email
        gender
        relationship
        otherRelationship
        roles
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        religion
        anniversaryDate
      }
      executor
      backupExecutors
      willLocation {
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        nameOfProperty
        additionalInstructions
        notifyExecutor
        latLong
      }
      funeralArrangement {
        religion
        howToConduct
        whereToConduct
        additionalInstruction
      }
      paymentInfo {
        paidWill
        gracePeriod
        paymentId
      }
      witnesses
      signingDocuments {
        pictureURLs
        videoURL
        videoUploadedAt
      }
      updatedOn
      penalty
      executorConsent
    }
  }
`;

export const DELETE_USER_LIABILITY = gql`
  mutation deleteLiability($input: DeleteLiabilityInput!) {
    deleteLiability(input: $input) {
      isWillUpdated
      documentId
      dateOfCreation
      willCompletionProgress
      dateOfSignature
      will_progress
      type
      testatorDetails {
        userId
        fullName
        fatherName
        dateOfBirth
        phoneNumber
        email
        gender
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        religion
        anniversaryDate
      }
      organizations {
        name
        pointOfContact
        phoneNumber
        website
        instructions
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
      }
      assetDistribution {
        assetId
        distribution {
          beneficiaryId
          beneficiaryName
          relationship
          percentageShare
        }
      }
      residuaryDistribution {
        distribution {
          beneficiaryId
          beneficiaryName
          relationship
          percentageShare
        }
      }
      assetTypes {
        BUSINESS
        FINANCIAL
        IMMOVABLE_PROPERTY
        OTHER
      }
      liabilityTypes {
        LOAN
      }
      assets {
        assetDocuments {
          documentName
          documentUrls
          tags
          currentOwner
          assetType
          assetSubType
          assetDocumentId
        }
        pointOfContact {
          name
          contactNumber
          email
        }
        documentId
        ownershipHistory {
          ownerId
          ownerFrom
          ownerTill
        }
        isClaimed
        propertyName
        surveyNumber
        description
        assetType
        assetSubType
        natureOfHolding
        typeOfBankAccount
        nameOfFirm
        nameOfCompany
        amount
        bankName
        nameOfDP
        dematAccountNumber
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        brandName
        paintingName
        websiteName
        profileLink
        details
        regNo
        panCardNumber
        jewelleryItem
        hufName
        hufShare
        name
        yearOfManufature
        percentageOfHolding
        sizeOfProperty
        surveyNumber
        propertySubType
        branchName
        ifscCode
        accountNumber
        institutionName
        folioNumber
        depositoryParticipant
        fdNumber
        policyNumber
        sumAssured
        namePostOffice
        uanNumber
        bankName
        registrationNumber
        model
        brand
        kind
        weight
        artistName
        description
        accountType
        exchangeDetails
        coldWallet
        digitalWallet
        eWalletProvider
        wealthManagerName
        fundName
        brokingAgentName
        certificateNumber
        serviceProviderName
        eiaNumber
        employerName
        typeOfSecurity
        hpns
        typeOfCryptoWallet
        employerId
        dpId
        nominees {
          fullName
          contactId
        }
        jointHolderName
      }
      liabilities {
        currentOwner
        documentId
        liabilityType
        liabilitySubType
        liabilityDetails {
          detailsOfInstitution
          accountNumber
          loanAmount
          description
        }
      }
      guardians {
        guardian
        children
      }
      backupGuardians
      beneficiaries {
        documentId
        relatedTo
        fullName
        dateOfBirth
        phoneNumber
        email
        gender
        relationship
        otherRelationship
        roles
        idType
        idNumber
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        religion
        anniversaryDate
      }
      executor
      backupExecutors
      willLocation {
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        nameOfProperty
        additionalInstructions
        notifyExecutor
        latLong
      }
      funeralArrangement {
        religion
        howToConduct
        whereToConduct
        additionalInstruction
      }
      paymentInfo {
        paidWill
        gracePeriod
        paymentId
      }
      witnesses
      signingDocuments {
        pictureURLs
        videoURL
        videoUploadedAt
      }
      updatedOn
      penalty
      executorConsent
    }
  }
`;

const DELETE_USER_CONTACT = gql`
  mutation DeleteUserContact($documentID: String!) {
    deleteContact(documentID: $documentID) {
      documentId
      relatedTo
      fullName
      email
      phoneNumber
      dateOfBirth
      anniversaryDate
      gender
      religion
      address {
        addressLine1
        addressLine2
        city
        state
        pinCode
        country
      }
      relationship
      otherRelationship
      roles
      idType
      idNumber
    }
  }
`;

const ADD_ASSET = gql`
  mutation AddUserAsset($input: AssetInput!) {
    addAsset(input: $input) {
      documentId
      currentOwner
      ownershipHistory {
        ownerId
        ownerFrom
        ownerTill
      }
      isClaimed
      assetType
      assetSubType
      natureOfHolding
      nameOfFirm
      address {
        addressLine1
        city
        state
        pinCode
      }
      percentageOfHolding
      sizeOfProperty
      surveyNumber
      branchName
      accountNumber
      institutionName
      folioNumber
      depositoryParticipant
      fdNumber
      policyNumber
      sumAssured
      namePostOffice
      uanNumber
      bankName
      typeOfCryptoWallet
      ifscCode
      propertySubType
      policyName
      typeOfInsurance
      additionalNotes
      pointOfContact {
        name
        contactNumber
        email
      }
    }
  }
`;

const CREATE_USER_WILL = gql`
  mutation CreateUserWill($input: WillInput!) {
    createWill(input: $input) {
      isWillUpdated
      documentId
      dateOfCreation
      willCompletionProgress
      isAmongFirstCustomer
      isPrintCourierRequestPaid
      dateOfSignature
      will_progress
      type
      testatorDetails {
        userId
        fullName
        fatherName
        dateOfBirth
        phoneNumber
        email
        gender
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        religion
        anniversaryDate
      }
      organizations {
        name
        pointOfContact
        phoneNumber
        website
        instructions
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
      }
      assetDistribution {
        assetId
        distribution {
          beneficiaryId
          beneficiaryName
          relationship
          percentageShare
        }
      }
      residuaryDistribution {
        distribution {
          beneficiaryId
          beneficiaryName
          relationship
          percentageShare
        }
      }
      assetTypes {
        BUSINESS
        FINANCIAL
        IMMOVABLE_PROPERTY
        OTHER
      }
      liabilityTypes {
        LOAN
      }
      assets {
        assetDocuments {
          documentName
          documentUrls
          tags
          currentOwner
          assetType
          assetSubType
          assetDocumentId
        }
        pointOfContact {
          name
          contactNumber
          email
        }
        documentId
        ownershipHistory {
          ownerId
          ownerFrom
          ownerTill
        }
        isClaimed
        propertyName
        surveyNumber
        description
        assetType
        assetSubType
        natureOfHolding
        nameOfFirm
        nameOfCompany
        amount
        bankName
        typeOfBankAccount
        nameOfDP
        dematAccountNumber
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        brandName
        paintingName
        websiteName
        profileLink
        details
        regNo
        panCardNumber
        jewelleryItem
        hufName
        hufShare
        name
        yearOfManufature
        percentageOfHolding
        sizeOfProperty
        surveyNumber
        propertySubType
        branchName
        ifscCode
        accountNumber
        institutionName
        folioNumber
        depositoryParticipant
        fdNumber
        policyNumber
        sumAssured
        namePostOffice
        uanNumber
        bankName
        registrationNumber
        model
        brand
        kind
        weight
        artistName
        description
        accountType
        exchangeDetails
        coldWallet
        digitalWallet
        eWalletProvider
        wealthManagerName
        fundName
        brokingAgentName
        certificateNumber
        serviceProviderName
        eiaNumber
        employerName
        typeOfSecurity
        hpns
        typeOfCryptoWallet
        employerId
        dpId
        policyName
        typeOfInsurance
        additionalNotes
        nominees {
          fullName
          contactId
        }
        jointHolderName
      }
      liabilities {
        currentOwner
        documentId
        liabilityType
        liabilitySubType
        liabilityDetails {
          detailsOfInstitution
          accountNumber
          loanAmount
          description
        }
      }
      guardians {
        guardian
        children
      }
      backupGuardians
      beneficiaries {
        documentId
        relatedTo
        fullName
        dateOfBirth
        phoneNumber
        email
        gender
        relationship
        otherRelationship
        roles
        idType
        idNumber
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        religion
        anniversaryDate
      }
      executor
      backupExecutors
      willLocation {
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        nameOfProperty
        additionalInstructions
        notifyExecutor
        latLong
      }
      funeralArrangement {
        religion
        howToConduct
        whereToConduct
        additionalInstruction
      }
      paymentInfo {
        paidWill
        gracePeriod
        paymentId
      }
      organizations {
        name
        pointOfContact
        phoneNumber
        website
        instructions
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
      }
      witnesses
      courierRequestInfo {
        id
        userId
        email
        phoneNumber
        name
        mailingAddress {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        dateOfCreation {
          status
          date
          time
          timestamp
        }
        status
        expectedDateOfDelivery
        indiaPostTrackingID
      }
      signingDocuments {
        pictureURLs
        videoURL
        videoUploadedAt
      }
      updatedOn
      penalty
      executorConsent
    }
  }
`;

const UPDATE_USER_WILL = gql`
  mutation UpdateUserWill($input: WillInput!) {
    updateWill(input: $input) {
      isWillUpdated
      documentId
      dateOfCreation
      willCompletionProgress
      isAmongFirstCustomer
      isPrintCourierRequestPaid
      dateOfSignature
      will_progress
      type
      testatorDetails {
        userId
        fullName
        fatherName
        dateOfBirth
        phoneNumber
        email
        gender
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        religion
        anniversaryDate
      }
      organizations {
        name
        pointOfContact
        phoneNumber
        website
        instructions
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
      }
      assetDistribution {
        assetId
        distribution {
          beneficiaryId
          beneficiaryName
          relationship
          percentageShare
        }
      }
      residuaryDistribution {
        distribution {
          beneficiaryId
          beneficiaryName
          relationship
          percentageShare
        }
      }
      assetTypes {
        BUSINESS
        FINANCIAL
        IMMOVABLE_PROPERTY
        OTHER
      }
      liabilityTypes {
        LOAN
      }
      assets {
        assetDocuments {
          documentName
          documentUrls
          tags
          currentOwner
          assetType
          assetSubType
          assetDocumentId
        }
        pointOfContact {
          name
          contactNumber
          email
        }
        documentId
        ownershipHistory {
          ownerId
          ownerFrom
          ownerTill
        }
        isClaimed
        propertyName
        surveyNumber
        description
        assetType
        assetSubType
        natureOfHolding
        nameOfFirm
        nameOfCompany
        amount
        bankName
        typeOfBankAccount
        nameOfDP
        dematAccountNumber
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        brandName
        paintingName
        websiteName
        profileLink
        details
        regNo
        panCardNumber
        jewelleryItem
        hufName
        hufShare
        name
        description
        borrowerName
        loanAmount
        interestRate
        contactNumber
        loanDate
        noofUnits
        noofVested
        noofunVested
        yearOfManufature
        percentageOfHolding
        sizeOfProperty
        surveyNumber
        propertySubType
        branchName
        ifscCode
        accountNumber
        institutionName
        folioNumber
        depositoryParticipant
        fdNumber
        policyNumber
        sumAssured
        namePostOffice
        uanNumber
        bankName
        registrationNumber
        model
        brand
        kind
        weight
        artistName
        description
        accountType
        exchangeDetails
        coldWallet
        digitalWallet
        eWalletProvider
        wealthManagerName
        fundName
        brokingAgentName
        certificateNumber
        serviceProviderName
        eiaNumber
        employerName
        typeOfSecurity
        hpns
        isinNumber
        typeOfCryptoWallet
        employerId
        dpId
        value
        nav
        noofUnits
        policyName
        typeOfInsurance
        additionalNotes
        nominees {
          fullName
          contactId
        }
        jointHolderName
      }
      liabilities {
        currentOwner
        documentId
        liabilityType
        liabilitySubType
        liabilityDetails {
          detailsOfInstitution
          accountNumber
          loanAmount
          description
        }
      }
      guardians {
        guardian
        children
      }
      backupGuardians
      beneficiaries {
        documentId
        relatedTo
        fullName
        dateOfBirth
        phoneNumber
        email
        gender
        relationship
        otherRelationship
        roles
        idType
        idNumber
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        religion
        anniversaryDate
      }
      executor
      backupExecutors
      willLocation {
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        nameOfProperty
        additionalInstructions
        notifyExecutor
        latLong
      }
      funeralArrangement {
        religion
        howToConduct
        whereToConduct
        additionalInstruction
      }
      paymentInfo {
        paidWill
        gracePeriod
        paymentId
      }
      organizations {
        name
        pointOfContact
        phoneNumber
        website
        instructions
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
      }
      witnesses
      courierRequestInfo {
        id
        userId
        email
        phoneNumber
        name
        mailingAddress {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        dateOfCreation {
          status
          date
          time
          timestamp
        }
        status
        expectedDateOfDelivery
      }
      signingDocuments {
        pictureURLs
        videoURL
        videoUploadedAt
      }
      updatedOn
      penalty
      executorConsent
    }
  }
`;

const GENERATE_PREVIEW_OF_WILL = gql`
  mutation GenerateWillPreview($input: WillPdfInput!) {
    generateWillPdf(input: $input)
  }
`;

const CLEAR_KYC = gql`
  mutation {
    clearKyc
  }
`;

const SEARCH_QUERY = gql`
  query SearchQuery($input: String!) {
    search(input: $input)
  }
`;

const CREATE_COURIER_REQUEST = gql`
  mutation CreateCourierRequest($input: PostWillGenerationInput!) {
    createCourierRequest(input: $input) {
      id
      userId
      name
      phoneNumber
      email
      mailingAddress {
        addressLine1
        city
        state
        pinCode
      }
      status
      dateOfCreation {
        status
        date
        time
      }
      expectedDateOfDelivery
    }
  }
`;

const DELETE_COURIER_REQUEST = gql`
  mutation DeleteCourierRequest($requestId: String!) {
    deleteCourierRequest(requestId: $requestId)
  }
`;

const FETCH_CONFIG = gql`
  query {
    getYellowAppConfigs
  }
`;

const FETCH_CONTENT = gql`
  query {
    content {
      articleCategories {
        id
        orderOfAppearance
        icon
        label
        color
        slug
        title
        description
        type
      }
      faqCategories {
        id
        orderOfAppearance
        icon
        label
        color
        slug
        title
        description
        type
      }
      recommended {
        faqs {
          id
          categoryIds
          type
          question
          answer
          slug
        }
        articles {
          id
          categoryIds
          type
          title
          tagIds
          dateOfUpdation
          draft
          body
          readTime
          summary
          authorId
          mainImage
          slug
          featureOnWeb
          featureOnMobile
        }
        videos {
          id
          type
          title
          videoUrl
          thumbnail
        }
      }
      faqs {
        id
        categoryIds
        type
        question
        answer
        slug
      }
      articles {
        id
        categoryIds
        type
        tagIds
        dateOfUpdation
        title
        draft
        slug
        body
        readTime
        summary
        authorId
        mainImage
        featureOnWeb
        featureOnMobile
      }
      videos {
        id
        type
        title
        videoUrl
        thumbnail
      }
      tags {
        id
        title
        slug
        description
        type
      }
      authors {
        id
        profilePicture
        name
        facebookLink
        twitterLink
        linkedinLink
        description
        type
      }
      sampleWills {
        freeWill
        paidWill
      }
    }
  }
`;

const FETCH_CONTENT_BY_CATEGORY = gql`
  mutation FetchContentByCategory($input: String!) {
    getContentByCategory(input: $input) {
      categories {
        id
        orderOfAppearance
        icon
        title
        description
        type
      }
      recommended {
        faqs {
          id
          categoryIds
          type
          question
          answer
        }
        articles {
          id
          categoryIds
          type
          title
          draft
          body
          readTime
          summary
          author
          mainImage
        }
        videos {
          id
          type
          title
          videoUrl
        }
      }
      faqs {
        id
        categoryIds
        type
        question
        answer
      }
      articles {
        id
        categoryIds
        type
        title
        draft
        body
        readTime
        summary
        author
        mainImage
      }
      videos {
        id
        type
        title
        videoUrl
      }
      sampleWills {
        freeWill
        paidWill
      }
    }
  }
`;

const UPDATE_USER_CONTACT = gql`
  mutation editContact($input: ContactInput!) {
    editContact(input: $input) {
      documentId
      fullName
      gender
      dateOfBirth
      phoneNumber
      relatedTo
      email
      religion
      roles
      anniversaryDate
      idNumber
      idType
      secondaryBeneficiary {
        type
        charityExists
        charity {
          theme
          charityDetails {
            organization {
              name
              pointOfContact
              phoneNumber
              website
              instructions
              address {
                addressLine1
                addressLine2
                city
                state
                pinCode
                country
              }
            }
          }
        }
        customSplit {
          distribution {
            beneficiaryId
            beneficiaryName
            relationship
            percentageShare
          }
        }
      }
      relationship
      otherRelationship
      address {
        addressLine1
        addressLine2
        city
        state
        pinCode
        country
      }
    }
  }
`;

export const VALIDATE_COUPON = gql`
  query validateCouponByCode($input: String!) {
    validateCouponByCode(code: $input) {
      couponCode
      discount
      type
    }
  }
`;

const FETCH_CONTACTS = gql`
  query {
    me {
      contacts {
        documentId
        relatedTo
        fullName
        email
        phoneNumber
        dateOfBirth
        anniversaryDate
        gender
        religion
        address {
          addressLine1
          addressLine2
          city
          state
          pinCode
          country
        }
        relationship
        otherRelationship
        roles
        idType
        idNumber
        secondaryBeneficiary {
          type
          charityExists
          charity {
            theme
            charityDetails {
              organization {
                name
                pointOfContact
                phoneNumber
                website
                instructions
                address {
                  addressLine1
                  addressLine2
                  city
                  state
                  pinCode
                  country
                }
              }
            }
          }
          customSplit {
            distribution {
              beneficiaryId
              beneficiaryName
              relationship
              percentageShare
            }
          }
        }
      }
    }
  }
`;

const FETCH_PLAN_BY_USER_INFO = gql`
  query fetchPlanByUserInfo($input: UserInfo!) {
    fetchPlanByUserInfo(input: $input)
  }
`;

const FETCH_PLAN_BY_USER_INFO_DIRECT = gql`
  query fetchPlanByUserInfo {
    fetchPlanByUserInfo
  }
`;

// @ts-ignore
const INITIATE_CART_PAYMENT = gql`
  mutation initiateCartPayment($input: CartInput!) {
    initiateCartPayment(input: $input) {
      amountedCartItems {
        itemId
        itemType
        amount
      }
      netAmount
      fullAmount
      taxAmount
      oldDeductionAmount
      orderId
      receiptId
      couponDiscountAmount
    }
  }
`;

const FETCH_CURRENT_USER_PLANS = gql`
  query {
    fetchCurrentUserPlans
  }
`;

const GET_PAYMENT_HISTORY = gql`
  query {
    getPaymentHistory {
      cartItems {
        itemId
        itemType
      }
      orderId
      amount
      userId
      paymentInitiatedDate
      receiptId
      razorpayPaymentId
      paymentStatus
      paymentReason
      willId
    }
  }
`;

const GET_WILL_MAIL_RECIPIENTS = gql`
  query getWillMailRecipients {
    getWillMailRecipients {
      emailId
      fullName
      mailSendingResponse {
        id
        email
        status
        rejectReason
      }
    }
  }
`;

const ADD_WILL_MAIL_RECIPIENT = gql`
  mutation addWillMailRecipient($input: WillMailRecipientInput!) {
    addWillMailRecipient(input: $input) {
      emailId
      fullName
      mailSendingResponse {
        id
        email
        status
        rejectReason
      }
    }
  }
`;

export const GET_VALIDATED_KYC_RESULT = gql`
  mutation getValidatedKycResult($input: KycVerificationRequestInput!) {
    getValidatedKycResult(input: $input) {
      gender
      idNumber
      fullName
      fathersName
      dateOfBirth
    }
  }
`;

export const SET_MANUAL_KYC_CHANGES = gql`
  mutation setManualKycChanges($input: KycPostExtractedRequestInput!) {
    setManualKycChanges(input: $input) {
      gender
      idNumber
      fullName
      fathersName
      dateOfBirth
    }
  }
`;

export const GET_WILL_SIGNING_VIDEO_UPLOAD_URL = gql`
  query getWillSigningVideoUploadInfo {
    getWillSigningVideoUploadInfo {
      fileName
      url
    }
  }
`;

export const GET_WILL_SIGNING_VIDEO_DOWNLOAD_URL = gql`
  query getWillSigningVideoDownloadInfo($input: String!) {
    getWillSigningVideoDownloadInfo(input: $input) {
      fileName
      url
    }
  }
`;

const DELETE_WILL_SIGNING_DOCUMENTS = gql`
  mutation deleteWillSignedDocuments($willId: String!) {
    deleteWillSignedDocuments(willId: $willId)
  }
`;

const GET_LAST_UPLOADED_VIDEO = gql`
  query getLatestWillWithSigningVideo {
    getLatestWillWithSigningVideo {
      documentId
      signingDocuments {
        videoURL
        videoUploadedAt
        pictureURLs
      }
    }
  }
`;

const FETCH_WILL_RECIPIENTS = gql`
  query{
    fetchWillRecipients{
      userId
      recipients{
          fullName
          email
      }
    }
  }
`;

const ADD_WILL_RECIPIENT = gql`
  mutation addWillRecipient($input: RecipientContactInput!){
    addWillRecipient(input: $input){
      userId
      recipients{
          fullName
          email
      }
    }
  }
`;

export {
  UPDATE_USER_CONTACT,
  FETCH_USER_PROFILE,
  FETCH_LOGGED_OUT_CONFIG,
  UPDATE_USER_PROFILE,
  ADD_USER_CONTACT,
  EDIT_USER_CONTACT,
  GET_SIGNED_STORAGE_URL,
  DELETE_USER_ASSET,
  DELETE_USER_CONTACT,
  ADD_ASSET,
  CREATE_USER_WILL,
  UPDATE_USER_WILL,
  GENERATE_PREVIEW_OF_WILL,
  SEARCH_QUERY,
  CREATE_COURIER_REQUEST,
  DELETE_COURIER_REQUEST,
  FETCH_CONTENT,
  FETCH_CONTENT_BY_CATEGORY,
  FETCH_CONFIG,
  MAILCHIMP_SEND_MAIL_TEMPLATE,
  FETCH_CONTACTS,
  FETCH_PLAN_BY_USER_INFO,
  FETCH_PLAN_BY_USER_INFO_DIRECT,
  INITIATE_CART_PAYMENT,
  FETCH_CURRENT_USER_PLANS,
  GET_PAYMENT_HISTORY,
  GET_WILL_MAIL_RECIPIENTS,
  ADD_WILL_MAIL_RECIPIENT,
  CLEAR_KYC,
  DELETE_WILL_SIGNING_DOCUMENTS,
  GET_LAST_UPLOADED_VIDEO,
  FETCH_LOGGED_OUT_PLANS_CONFIG,
  FETCH_PAID_PLANS,
  DELETE_ACCOUNT,
  FETCH_WILL_RECIPIENTS,
  ADD_WILL_RECIPIENT
};
