import { ApolloClient, ApolloClientOptions, InMemoryCache, createHttpLink } from '@apollo/client';
import firebase from 'utils/firebase';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: `${import.meta.env.VITE_SERVER_BASE_URL}/graphql`,
});
const authLink = setContext((_, { headers }) => {
  // console.log('_********', _);
  async function getAuthHeaders() {
    const loggedInUser = firebase.auth().currentUser;
    const token = await loggedInUser?.getIdToken(true);
    // const token = localStorage.getItem('authToken');
    // console.log("This is token from Apollo File",token);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  }

  return getAuthHeaders();
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  request: async (ope: any) => console.log('Operation in Apollo **********', ope),
} as ApolloClientOptions<unknown>);

export default client;
