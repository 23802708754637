import { gql } from '@apollo/client';
import { Checkbox, Grid } from '@mui/material';
// import axios from 'axios';
// import BackToSectionSummary from 'Pages/MyWill/BackToSectionSummary';
import { useEffect, useState } from 'react';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { fetchCurrentUserPlans, fetchUserProfileAction } from 'store/user/slice';
import {
  selectActiveWill,
  selectConfig,
  selectCurrentUserPlans,
  selectUserProfile,
  selectUserStatus,
  selectYellowPlusDefaultPlansConfig,
} from 'store/user/selectors';
import { useNavigate } from 'react-router-dom';
import alertIcon from 'assets/svg/alertIcon.svg';
import discountIcon from 'assets/images/discountCouponIcon.png';
import styled from 'styled-components';
import { runMutation } from 'utils/graphQL';
import {
  PaymentInput,
  PaymentSignatureInput,
  WILL_TYPES,
} from 'utils/types';
import PayButton from 'common/forms/PayButton';
import { showToast, ToastMessage, ToastType } from 'store/toast/slice';
import { VALIDATE_COUPON } from 'common/queries';
import BackToSectionSummary from 'Pages/MyWill/BackToSectionSummary';
import { addToUserStatus, updatePaymentInfo, UserStatus } from 'store/user/slice';
import Loading from 'common/Loading';
import { Pages } from 'routes';
import { Amount } from 'utils/config/interfaces';
import { Mixpanel } from 'utils/mixpanel';
import { Intercom } from 'utils/intercom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import logo from '../../assets/svg/yellowLogo.svg';
// import { getEmptyAddress } from 'utils';
// import { PrintAndCourierAddressForm } from 'Pages/MyWill/PostWillGeneration/PrintAndCourier';
import { formatToInFormat } from '../../utils/NumberFormatter';
import Header from '../../common/Header';
import { breakpoints } from '../../styles/Breakpoints';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const DISABLE_WILL_SHIPMENT = true;

export const WillCreationLayoutWrapper = styled.div`
  .help_icon_shipping {
    cursor: pointer;
    height: 60px;
    width: 60px;
  }
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100vw;
    box-sizing: border-box;
    min-width: 100vw;
    max-width: 100vw;
    height: auto;
    padding: 0px;
    margin-top: 0px;
  }
  .container {
    /* overflow: hidden; */
    justify-content: space-between;
    margin-top: 55px;
    padding: 0px 80px;
    padding-bottom: 68px;
    @media screen and (max-width: ${breakpoints.md}) {
      width: 100vw;
      box-sizing: border-box;
      min-width: 100vw;
      max-width: 100vw;
      height: auto;
      padding: 0px;
      margin-top: 0px;
    }
    .label {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #101828;
      margin-bottom: 16px;
    }
    .stepInfo_holder {
      max-width: 100%;
      align-items: start;
      /* background-color: ${(props) => props.theme.light_beige}; */

      .image {
        height: 180px;
        object-fit: cover;
        object-position: top;

        margin-bottom: 50px;
      }

      .title {
        max-width: 80%;
        text-align: center;
        margin: 10px 0px 30px;
      }

      .stepInfo {
        max-width: 75%;
        margin: 0px auto;
        text-align: center;
      }
    }

    .will_steps_holder {
      display: flex;
      align-items: center;
      position: relative;
      overflow: auto;
      max-height: 100vh;
      padding-left: 10px;
      align-items: start;
      @media screen and (max-width: ${breakpoints.md}) {
        width: 100%;
        box-sizing: border-box;
        padding: 0px 16px;
        max-width: unset;
        overflow-y: scroll;
      }
      .head {
        .help_icon {
          cursor: pointer;
          height: 60px;
          width: 60px;
        }
      }
    }
    .will_steps_holder_2 {
      position: relative;
      overflow: auto;
      height: 100vh;
      max-height: 100vh;
    }
    .payments-text-heading {
      margin: 15px 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #101828;
    }
  }
`;

const PromoCodeWrapper = styled.div`
  margin: 10px 0px;

  form {
    /* This bit sets up the horizontal layout */
    display: flex;
    flex-direction: row;

    /* This bit draws the box around it */
    background: rgba(71, 84, 103, 0.05);

    /* I've used padding so you can see the edges of the elements. */
    padding: 10px 28px;
    border-radius: 10px;
  }

  input {
    /* Tell the input to use all the available space */
    flex-grow: 2;
    /* And hide the input's outline, so the form looks like the outline */
    border: none;
    padding: 0px 15px;
    border-radius: 10px;
    background: none;
    font-size: 15px;
  }

  input:focus {
    /* removing the input focus blue box. Put this on the form if you like. */
    outline: none;
  }

  button {
    /* Just a little styling to make it pretty */
    border: none;
    background: none;
    padding: 10px;
  }
`;

const InvoiceWrapper = styled(Grid)`
  flex-direction: column;
  margin: 30px 0px;
  .text-font {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #101828;
  }
  .small-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(16, 24, 40, 0.65);
  }
`;

const PromoCodeSuccess = styled(Grid)`
  background: #e7f6ea;
  border: 2px solid #41aa58;
  margin: 10px 0px;
  padding: 15px;
  border-radius: 10px;
  color: #41aa58;
`;

const PromoCodeFailed = styled(Grid)`
  background: #ffeeee;
  border: 2px solid #ba1b1b;
  margin: 10px 0px;
  padding: 15px;
  border-radius: 10px;
  color: #ba1b1b;
`;

const RadioGroupWrapper = styled.div`
  label {
    width: 685px;
    height: 72px;
    background: #fcfcfc;
    border-radius: 12px;
    margin: 3%;
  }
`;
// import styled from 'styled-components';

// const PaymentsWrapper = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   animation: moveLeft 2s ease-in forwards;
//   animation-delay: 1s;
//   animation-iteration-count: 1;

//   @keyframes moveLeft {
//     from {
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }
//     to {
//       left: 0%;
//       transform: translate(0%, 0%);
//     }
//   }
// `;

export enum PaymentReason {
  WILL_GENERATION = 'Will Generation',
  WILL_UPDATE = 'Will Update',
  COURIER_REQUEST = 'Courier Request',
  WILL_UPDATE_PLUS_COURIER = 'Will Update + Courier Request',
}

const PaymentsHome = () => {
  const currentTheme = useTheme();
  const lessThanSmall = useMediaQuery(currentTheme.breakpoints.down('sm'));

  const navigate = useNavigate();
  // const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isFirstLoadRequestInProgress, setIsFirstLoadRequestInProgress] = useState<boolean>(true);
  const [promoCode, setPromocode] = useState<string>('');
  const [successPromocode, setSuccessPromocode] = useState<boolean>(false);
  const [amount, setAmount] = useState<any>({});
  const [discountAmount, setDiscountAmount] = useState(0);
  const userProfile = useAppSelector(selectUserProfile);
  const userStatus = useAppSelector(selectUserStatus);
  const config = useAppSelector(selectConfig);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useTypedDispatch();
  const [initiatePaymentResult, setInitiatePaymentResult] = useState();
  const isFirstWillComplete = () => userStatus.includes(UserStatus.FIRST_WILL_COMPLETED);
  const [invalidPromoCode, setInvalidPromoCode] = useState(false);
  const [willShipment, setWillShipment] = useState(false);
  const [personalAddress, setPersonalAddress] = useState(true);
  const userActiveWill = useAppSelector(selectActiveWill);
  const [courierAmount, setCourierAmount] = useState<number>(3);
  const [taxAmount, setTaxAmount] = useState<number>(0);

  const currentUserPlansResponse = useAppSelector(selectCurrentUserPlans);
  const yellowPlusConfig = useAppSelector(selectYellowPlusDefaultPlansConfig);
  const currentPlan = currentUserPlansResponse?.data?.fetchPlans?.[0];
  const planInfo = yellowPlusConfig?.plans?.find(
    (plan: any) => plan?.id === currentPlan?.planId || 'starter',
  );

  useEffect(() => {
    setCourierAmount(3);
    // if(planInfo && planInfo.title){
    //   if(planInfo.title.toLowerCase() === 'select' || planInfo.title.toLowerCase() === 'elite'){
    //     setCourierAmount(0)
    //   }
    // }
  }, [planInfo]);

  //   const [showScreen, setShow] = useState(false);
  //   useEffect(() => {
  //     setTimeout(() => {
  //       setShow(true);
  //     }, 3500);
  //   }, []);

  const loadScript = (src: any) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const getAmountToCharge = () =>
    willShipment
      ? successPromocode
        ? amount.basePrice - discountAmount + taxAmount + courierAmount
        : amount.grossAmount + courierAmount
      : successPromocode
        ? amount.basePrice - discountAmount + taxAmount
        : amount.basePrice + taxAmount;

  const handlePayments = async () => {
    Mixpanel.track('Proceeded to Razorpay');
    Intercom.track('Proceeded to Razorpay');
    // console.log('Clicked');
    // creating a new order
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }
    // const result = await axios.post('http://localhost:5000/payment/orders');

    // if (!result) {
    //   alert('Server error. Are you online?');
    //   return;
    // }

    // const result = await runMutation({
    //   mutation: gql`
    //     mutation initiatePayment($input: PaymentInput!) {
    //       initiatePayment(input: $input) {
    //         orderId
    //         receiptId
    //       }
    //     }
    //   `,
    //   variables: {
    //     key: 'input',
    //     value: {
    //       amount: (amount - discountAmount) * 100,
    //       willType: WILL_TYPES.PREMIUM_WILL,
    //       willId: userActiveWill?.documentId,
    //       paymentReason: isFirstWillComplete()
    //         ? PaymentReason.WILL_UPDATE
    //         : PaymentReason.WILL_GENERATION,
    //     } as PaymentInput,
    //   },
    // });

    // console.log('This is result', result);

    // // Getting the order details back
    const { initiatePayment } = (initiatePaymentResult!! as any).data;
    // console.log(initiatePayment);

    const amount = getAmountToCharge() * 100;

    const options = {
      key: import.meta.env.VITE_RAZORPAY_TEST_KEYID,
      amount,
      name: 'Yellow Company',
      description: WILL_TYPES.PREMIUM_WILL,
      order_id: initiatePayment.orderId,
      handler: async function(response: any) {
        // * Payment Info Coming from Razorpay
        // const data = {
        //   razorpayPaymentId: response.razorpay_payment_id,
        //   razorpayOrderId: response.razorpay_order_id,
        //   razorpaySignature: response.razorpay_signature,
        // };
        // console.log(data);

        const sendDataToServer = await runMutation({
          mutation: gql`
            mutation verifyPaymentSignature($input: PaymentSignatureInput!) {
              verifyPaymentSignature(input: $input) {
                orderId
                amount
                userId
                paymentInitiatedDate
                receiptId
                razorpayPaymentId
                paymentStatus
                willType
              }
            }
          `,
          variables: {
            key: 'input',
            value: {
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
              receiptId: initiatePayment.receiptId,
            } as PaymentSignatureInput,
          },
        });

        console.log('Payment Info from Server', sendDataToServer);

        const {
          data: { getActiveWill },
        } = await runMutation({
          mutation: gql`
            query {
              getActiveWill {
                documentId
                paymentInfo {
                  gracePeriod
                  paidWill
                  paymentId
                }
              }
            }
          `,
          variables: {
            key: 'input',
            value: null,
          },
        });

        dispatch(updatePaymentInfo(getActiveWill.paymentInfo));

        dispatch(addToUserStatus(UserStatus.ACTION_SET_PAYMENT_COMPLETED));
        await new Promise((resolve, reject) =>
          dispatch(fetchCurrentUserPlans(undefined, resolve, reject)),
        );
        const toast: ToastMessage = {
          type: ToastType.SUCCESS,
          message: 'Payment Success!!',
        };
        dispatch(showToast(toast));
        Mixpanel.track('Payment Successful', {
          from: 'Will Flow',
          amount: getAmountToCharge(),
          ...(promoCode && successPromocode ? { code: promoCode } : {}),
        });

        Mixpanel.setUserProperties({ 'Quick Cart': 'No' });

        Intercom.track('Payment Successful', {
          from: 'Will Flow',
          amount: getAmountToCharge(),
          ...(promoCode && successPromocode ? { code: promoCode } : {}),
        });

        navigate(Pages.MY_WILL);
        //navigate(Pages.CONGRATULATIONS);
      },
      prefill: {
        email: userProfile?.email,
        contact: userProfile?.phoneNumber,
      },
    };

    const paymentObject = new (window as any).Razorpay(options);
    console.log('options', options);
    paymentObject.open();
    paymentObject.on('payment.failed', (response: any) => {
      const { error } = response;
      Mixpanel.track('Payment failed');
      console.log('Erro Response', error);
      const toast: ToastMessage = {
        type: ToastType.ERROR,
        message: 'Payment failed, try again!!',
      };
      dispatch(showToast(toast));

      navigate(Pages.WILL_EDITOR);
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
  };

  const handleApplyPromoCode = async (e: any) => {
    e.preventDefault();
    console.log('This is promocode', promoCode);
    Mixpanel.track('Coupon Added', { code: promoCode });
    try {
      const sendDataToServer: any = await runMutation({
        mutation: VALIDATE_COUPON,
        variables: {
          key: 'input',
          value: promoCode as string,
        },
      });
      if (
        !sendDataToServer ||
        !sendDataToServer.data ||
        !sendDataToServer.data.validateCouponByCode
      ) {
        return dispatch(
          showToast({
            type: ToastType.ERROR,
            message: 'Invalid Coupon Code!!',
          }),
        );
      }
      const { discount } = sendDataToServer.data.validateCouponByCode;
      const calcDiscountAmount = Math.round((amount.basePrice / 100) * parseFloat(discount));
      if (calcDiscountAmount) {
        setDiscountAmount(calcDiscountAmount);
      }

      const reason = isFirstWillComplete()
        ? PaymentReason.WILL_UPDATE
        : PaymentReason.WILL_GENERATION;
      const response = await checkForPayment(amount, reason);
      setInitiatePaymentResult(response);
      setSuccessPromocode(true);
      setInvalidPromoCode(false);
    } catch (error) {
      setInvalidPromoCode(true);
      setSuccessPromocode(false);
      setDiscountAmount(0);
    }
  };

  // const getPaymentData = async () => {
  //   const {
  //     data: { getActiveWill },
  //   } = await runMutation({
  //     mutation: gql`
  //       query {
  //         getActiveWill {
  //           documentId
  //           paymentInfo {
  //             gracePeriod
  //             paidWill
  //             paymentId
  //           }
  //         }
  //       }
  //     `,
  //     variables: {
  //       key: 'input',
  //       value: null,
  //     },
  //   });
  //   console.log('Payment Info', getActiveWill);
  // };

  if (false) {
    {
      /* Trying to call all the unnecessary things, but their values used somewhere in main content of this page */
    }
    setAmount(1);
    setPromocode('');
    setSuccessPromocode(false);
  }

  const checkForPayment = async (amount: Amount, reason: PaymentReason) => {
    const finalPayment = amount.basePrice - discountAmount + taxAmount;
    if (finalPayment > 0) {
      const result = await runMutation({
        mutation: gql`
          mutation initiatePayment($input: PaymentInput!) {
            initiatePayment(input: $input) {
              orderId
              receiptId
            }
          }
        `,
        variables: {
          key: 'input',
          value: {
            amount: finalPayment * 100,
            willType: WILL_TYPES.PREMIUM_WILL,
            willId: userActiveWill?.documentId,
            paymentReason: reason,
          } as PaymentInput,
        },
      });
      return result;
    }
    return null;
  };

  const handleValueChange = (e: any) => {
    setPromocode('');
    setSuccessPromocode(false);
    setInvalidPromoCode(false);
    const newValue = e.target.value === 'yes';
    setWillShipment(newValue);
  };

  useEffect(() => {
    if (!userActiveWill) return;
    (async () => {
      try {
        setIsLoading(true);
        const isSecondWill = isFirstWillComplete();
        const amount: Amount = isSecondWill
          ? config!!.configs.mobilePlans['Update Premium Will']
          : config!!.configs.mobilePlans['Premium Will'];
        const finalPayment = amount.basePrice - discountAmount + taxAmount;
        const reason = isSecondWill ? PaymentReason.WILL_UPDATE : PaymentReason.WILL_GENERATION;
        await runMutation({
          mutation: gql`
            mutation initiatePayment($input: PaymentInput!) {
              initiatePayment(input: $input) {
                orderId
                receiptId
              }
            }
          `,
          variables: {
            key: 'input',
            value: {
              amount: finalPayment * 100,
              willType: WILL_TYPES.PREMIUM_WILL,
              willId: userActiveWill?.documentId,
              paymentReason: reason,
            } as PaymentInput,
          },
        });
        setIsFirstLoadRequestInProgress(false);
        setIsLoading(false);
      } catch (err: any) {
        if ((err as Error).message.indexOf('NO_PAYMENT_REQUIRED') > -1) {
          dispatch(addToUserStatus(UserStatus.ACTION_SET_PAYMENT_COMPLETED));
          dispatch(fetchUserProfileAction());
          navigate(Pages.MY_WILL, { replace: true });
          setIsFirstLoadRequestInProgress(false);
          setIsLoading(false);
        }
      }
    })();
  }, [userActiveWill, config]);

  useEffect(() => {
    (async () => {
      if (userStatus.includes(UserStatus.LOGGED_OUT)) {
        setIsLoading(false);
        navigate(Pages.HOME, { replace: true });
      }

      if (userStatus.includes(UserStatus.PROFILE_LOADED)) {
        // If will is not complete, redirect to will editor page
        if (userStatus.includes(UserStatus.WILL_CREATION_INCOMPLETE)) navigate(Pages.WILL_EDITOR);

        if (isFirstLoadRequestInProgress) return;
        const isSecondWill = isFirstWillComplete();
        const amount: Amount = isSecondWill
          ? config!!.configs.mobilePlans['Update Premium Will']
          : config!!.configs.mobilePlans['Premium Will'];
        const reason = isSecondWill ? PaymentReason.WILL_UPDATE : PaymentReason.WILL_GENERATION;

        try {
          const response = await checkForPayment(amount, reason);
          setInitiatePaymentResult(response);
          const updatedAmount: Amount = {
            ...amount,
            gstAmount: taxAmount,
          } as Amount;
          setAmount(updatedAmount);
          setIsLoading(false);
        } catch (err) {
          if ((err as Error).message.indexOf('NO_PAYMENT_REQUIRED') > -1) {
            dispatch(addToUserStatus(UserStatus.ACTION_SET_PAYMENT_COMPLETED));
            dispatch(fetchUserProfileAction());
            // navigate(Pages.MY_WILL, { replace: true });
          }
        }
      }
    })();
  }, [
    userStatus,
    discountAmount,
    successPromocode,
    taxAmount,
    invalidPromoCode,
    isFirstLoadRequestInProgress,
  ]);

  useEffect(() => {
    if (amount) {
      const tax = Math.round((amount.basePrice - discountAmount) * 0.18);
      setTaxAmount(tax);
    }
  }, [discountAmount, amount]);

  if (isLoading) return <Loading />;

  return (
    // <PaymentsWrapper>
    //   <p>PaymentsHome</p>
    //   {showScreen && <p>Hey!!</p>}
    // </PaymentsWrapper>
    // <div>
    //   {!paymentSuccess ? (
    //     <>
    //       <p>Payments Home!!</p>
    //       <button onClick={() => setPaymentSuccess(true)}>Show Congratulations</button>
    //       <br />
    //       <br />
    //       <button onClick={() => navigate(Pages.HOME)}>Back Home</button>
    //     </>
    //   ) : (
    //     <Congratulations />
    //   )}
    // </div>

    <>
      {
        <WillCreationLayoutWrapper>
          <Grid
            className={'hide_in_mobile'}
            style={{
              padding: '20px 85px',
              borderBottom: '1px solid rgba(29, 41, 57, 0.16)',
            }}
          >
            <img style={{ width: '112px' }} src={logo} alt='logo' />
          </Grid>
          <Grid
            className={'show_in_mobile'}
            style={{
              padding: '16px',
            }}
          >
            <Header
              title={'Payment Summary'}
              centerItems={true}
              backButton={true}
              backButtonOnClick={() => navigate(Pages.WILL_EDITOR)}
            />
          </Grid>
          <div className={'hide_in_mobile'} style={{ position: 'relative', top: 0 }}>
            <BackToSectionSummary
              text='Back to Will Editor'
              action={() => navigate(Pages.WILL_EDITOR)}
            />
          </div>
          <Grid
            container
            lg={12}
            className='container'
            style={{ ...(lessThanSmall ? { paddingBottom: '300px' } : {}) }}
          >
            {DISABLE_WILL_SHIPMENT ? null : (
              <Grid
                lg={4}
                md={4}
                sm={6}
                display='flex'
                flexDirection={'column'}
                alignItems='center'
                className='stepInfo_holder'
              // sx={{ backgroundColor: '#FFF8DD' }}
              >
                {/* <h6 className='subText font-semi-bold'>Payment Summary</h6> */}
                <p style={{ fontSize: '32px', marginBottom: '32px' }}>Domestic Shipping</p>
                <p style={{ fontWeight: '500', fontSize: '20px' }}>
                  What would you like to do once the final Will is ready?
                </p>
                <div
                  style={{
                    width: '733px',
                    height: '117px',
                    marginTop: '16px',
                    background: '#F3F4F5',
                    borderRadius: '12px',
                  }}
                >
                  <RadioGroupWrapper>
                    <RadioGroup
                      // defaultValue = {willShipment ? 'yes' : 'no'}
                      style={{
                        position: 'relative',
                      }}
                      sx={{
                        display: 'block',
                      }}
                      aria-label='will-shipment'
                      name='will-shipment'
                      value={willShipment ? 'yes' : 'no'}
                      onChange={handleValueChange}
                    >
                      <FormControlLabel
                        value='no'
                        control={
                          <Radio
                            sx={{
                              display: 'block',
                              '&.Mui-checked': {
                                color: '#1D2939 !important',
                              },
                            }}
                          />
                        }
                        label='I’ll print it on my own'
                      />

                      {/* <FormControlLabel 
                    value="yes" 
                    sx={{
                      '.MuiFormControlLabel-label':{
                        width:'100%'
                      }
                    }}
                    control={<Radio  sx={{
                      display: "block",
                      '&.Mui-checked': {
                        color: '#1D2939 !important',
                      },
                    }} 
                    style={{
                          position: 'relative',
                          top: '-12px',
                          padding: '0',
                          left: '6px',
                    }} />} 
                    label={
                      <div style={{
                        width:'100%'
                      }}>
                      <span style={{
                            marginLeft: '14px',
                            fontWeight: '500',
                            fontSize: '18px',
                            lineHeight: '26px',
                            color: '#101828',
                            marginTop:'-20px',
                            width: '100%',
                            display: 'inline-flex',
                            justifyContent: 'space-between',
                      }}>Ship it to me 
                      <span style={{
                        paddingRight: '25px'
                      }}>+ ₹3</span></span> 
                        <div style={{
                          display:'flex', alignItems:'center' ,marginTop:'8px',
                          position: 'relative',
                          left: '-13px',
                        }}>
                        <img style={{
                          marginRight:'10px', width:'15px'
                        }} src={infoIcon}/>  
                        <p style={{fontWeight: '400', fontSize: '14px',
                          lineHeight: '18px',color:' #2F44B1'}}>Currently, we are unable to fulfill international shipping requests</p>
                        </div> 
                      </div>
                      }
                  /> */}
                    </RadioGroup>
                  </RadioGroupWrapper>
                </div>

                {willShipment && (
                  <>
                    <p style={{ fontSize: '20px', marginTop: '50px' }}>Shipping Details</p>
                    {/* {personalAddress && ( */}
                    {/*   <PrintAndCourierAddressForm */}
                    {/*     // showTracker={() => setShowStepper(true)} */}
                    {/*     style={{ width: '733px' }} */}
                    {/*     defaultValues={ */}
                    {/*       { */}
                    {/*         email: userActiveWill && userActiveWill.testatorDetails?.email, */}
                    {/*         name: userActiveWill && userActiveWill.testatorDetails?.fullName, */}
                    {/*         phoneNumber: */}
                    {/*           userActiveWill && userActiveWill.testatorDetails?.phoneNumber, */}
                    {/*         mailingAddress: userActiveWill */}
                    {/*           ? userActiveWill.testatorDetails?.address */}
                    {/*           : getEmptyAddress(), */}
                    {/*       } as PrintAndCourierDetails */}
                    {/*     } */}
                    {/*     handlePayments={handlePayments} */}
                    {/*     personalAddress={personalAddress} */}
                    {/*     printAndCourierScreen={-1} */}
                    {/*     setLoading={() => null} */}
                    {/*     setPrintAndCourierScreen={() => null} */}
                    {/*     submitWholeForm={false} */}
                    {/*   /> */}
                    {/* )} */}
                    {/* {!personalAddress && ( */}
                    {/*   <PrintAndCourierAddressForm */}
                    {/*     // showTracker={() => setShowStepper(true)} */}
                    {/*     defaultValues={ */}
                    {/*       { */}
                    {/*         email: userActiveWill && userActiveWill.testatorDetails?.email, */}
                    {/*         name: userActiveWill && userActiveWill.testatorDetails?.fullName, */}
                    {/*         phoneNumber: */}
                    {/*           userActiveWill && userActiveWill.testatorDetails?.phoneNumber, */}
                    {/*       } as PrintAndCourierDetails */}
                    {/*     } */}
                    {/*     style={{ width: '733px' }} */}
                    {/*     handlePayments={handlePayments} */}
                    {/*     printAndCourierScreen={-1} */}
                    {/*     setLoading={() => null} */}
                    {/*     setPrintAndCourierScreen={() => null} */}
                    {/*     submitWholeForm={false} */}
                    {/*   /> */}
                    {/* )} */}
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <Checkbox
                        checked={personalAddress}
                        sx={{
                          '&.Mui-checked': {
                            color: '#1D2939',
                          },
                        }}
                        onChange={() => setPersonalAddress(!personalAddress)}
                      />
                      Same as address in Personal Details
                    </div>
                  </>
                )}

                {/* <img src={payments} alt='Payment Logo' className='image' /> */}
                {/* <p className='subText font-semi-bold stepInfo'>{stepInfo}</p> */}
              </Grid>
            )}

            <Grid
              lg={DISABLE_WILL_SHIPMENT ? 12 : 4}
              md={DISABLE_WILL_SHIPMENT ? 12 : 3}
              sm={DISABLE_WILL_SHIPMENT ? 12 : 6}
              className='will_steps_holder'
            >
              <Grid
                container
                flexDirection={'column'}
                // justifyContent={'center'}
                // alignItems={'center'}
                className='head'
                lg={12}
                md={12}
                sm={12}
              >
                {/* <BackToSectionSummary
              text='Back to Will Editor'
              action={() => navigate(Pages.MY_WILL)}
            /> */}
                <Grid>
                  <>
                    <p
                      className={'hide_in_mobile'}
                      style={{ fontSize: '24px', marginBottom: '32px' }}
                    >
                      Payment Summary
                    </p>
                    {/* {willShipment && ( */}
                    <>
                      <div className='payments-text-heading'>Have a promotional code?</div>
                      <PromoCodeWrapper>
                        <form
                          style={{ border: invalidPromoCode ? '2px solid #BA1B1B' : '' }}
                          onSubmit={handleApplyPromoCode}
                        >
                          <img
                            src={discountIcon}
                            style={{ width: '20px', height: '20px', margin: 'auto' }}
                            alt=''
                          />
                          <input
                            type='text'
                            name='promoCode'
                            value={promoCode}
                            placeholder='Enter here'
                            onChange={(e) => {
                              setPromocode(e.target.value.toUpperCase());
                              setSuccessPromocode(false);
                              setInvalidPromoCode(false);
                              setDiscountAmount(0);
                            }}
                          />
                          <button
                            style={{
                              padding: '0',
                              fontWeight: '700',
                              fontSize: '14px',
                              lineHeight: '22px',
                              textAlign: 'right',
                              color: '#1D2939',
                              cursor: 'pointer',
                            }}
                            disabled={promoCode === ''}
                            type='submit'
                          >
                            APPLY
                          </button>
                        </form>
                      </PromoCodeWrapper>
                    </>
                    {/* )} */}
                  </>
                  {successPromocode ? (
                    <PromoCodeSuccess>
                      <Grid display={'flex'}>
                        <div>🎉</div>
                        <div style={{ marginLeft: '12px' }}>
                          <p style={{ fontWeight: 'bold', marginBottom: '8px' }}>Congratulations</p>
                          <div style={{ fontSize: '13px' }}>
                            You just saved ₹{discountAmount} on your Will
                          </div>
                        </div>
                      </Grid>
                    </PromoCodeSuccess>
                  ) : null}
                  {invalidPromoCode ? (
                    <PromoCodeFailed>
                      <Grid display={'flex'}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <img src={alertIcon} style={{ width: '35px', height: '35px' }} alt='' />
                          <div style={{ marginLeft: '12px' }}>
                            <p style={{ fontWeight: 'bold', marginBottom: '8px' }}>Invalid Code</p>
                            <div style={{ fontSize: '13px' }}>
                              The code you have entered is expired or incorrect
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </PromoCodeFailed>
                  ) : null}
                  {/* Invoice Wrapper */}
                  <InvoiceWrapper>
                    <div className='payments-text-heading'>Invoice</div>
                    <Grid
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      style={{ margin: '15px 0' }}
                    >
                      <div>
                        <p className='text-font'>Yellow {planInfo?.title} Plan</p>
                        <p className='small-text' style={{ color: 'rgba(16, 24, 40, 0.65)' }}>
                          1 Year : Yellow Will
                        </p>
                      </div>
                      <p className='text-font'>₹ {formatToInFormat(amount.basePrice)}</p>
                    </Grid>
                    <hr style={{ color: '#DFDFDF' }} />
                    <Grid
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      style={{ margin: '15px 0' }}
                    >
                      <p className='text-font'>Sub Total</p>
                      <p className='text-font'>₹ {formatToInFormat(amount.basePrice)}</p>
                    </Grid>
                    {successPromocode ? (
                      <Grid
                        style={{ margin: '15px 0' }}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                      >
                        <p className='text-font'>Cashback (Will)</p>
                        <p className='text-font' style={{ color: '#41AA58' }}>
                          - ₹ {formatToInFormat(discountAmount)}
                        </p>
                      </Grid>
                    ) : (
                      <>
                        <Grid
                          style={{ margin: '15px 0' }}
                          display={'flex'}
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                        >
                          <p className='text-font'>Shipping & Handling charges</p>
                          <p className='text-font' style={{ color: willShipment ? '#41AA58' : '' }}>
                            {willShipment && courierAmount > 0 ? '+₹ 3' : 'NA'}
                          </p>
                        </Grid>
                        <Grid
                          display={'flex'}
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                          style={{ margin: '15px 0' }}
                        >
                          <p className='text-font'>Total Tax</p>
                          <p className='text-font'>{`₹ ${formatToInFormat(taxAmount)}`}</p>
                        </Grid>
                        <hr />
                      </>
                    )}
                    {successPromocode ? (
                      <>
                        {/* <Grid
                          display={'flex'}
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                          style={{ margin: '15px 0' }}
                        >
                          <p className='text-font'>Sub Total</p>
                          <p className='text-font'>₹ {amount.basePrice - discountAmount}</p>
                        </Grid> */}
                        <Grid
                          style={{ margin: '15px 0' }}
                          display={'flex'}
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                        >
                          <p className='text-font'>Shipping & Handling charges</p>
                          <p
                            className='text-font'
                          // style={{ color: '#3C8B3B' }}
                          >
                            NA
                          </p>
                        </Grid>
                        <Grid
                          display={'flex'}
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                          style={{ margin: '15px 0' }}
                        >
                          <p className='text-font'>Total Tax</p>
                          <p className='text-font'>
                            {taxAmount > 0 ? `₹ ${formatToInFormat(taxAmount)}` : 'NA'}
                          </p>
                          {/* <p className='text-font'>{(Math.floor((amount.basePrice - discountAmount) * 0.18))}</p> */}
                        </Grid>
                        <hr />
                      </>
                    ) : null}
                    <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                      <div className='payments-text-heading'>Amount Payable</div>
                      <div className='payments-text-heading'>{`₹ ${formatToInFormat(
                        getAmountToCharge(),
                      )}`}</div>
                    </Grid>
                  </InvoiceWrapper>
                  {/* <Grid display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    <img
                      style={{ color: '#ddd' }}
                      src={assetsIcons.tooltip}
                      alt=''
                      className='cursor-pointer tooltipIcon'
                    />
                    <p style={{ fontSize: '14px', marginLeft: '10px', color: '#aaa' }}>
                      You will be able to choose how you would like your Will sent to you after
                      payment is complete
                    </p>
                  </Grid> */}
                </Grid>
                {/* <button onClick={handlePayments}>Pay</button> */}
              </Grid>
            </Grid>
          </Grid>
          <PayButton
            button={!willShipment}
            text={`Yellow ${planInfo?.title ? planInfo.title : ''} Plan`}
            onClick={() => handlePayments()}
            totalAmount={getAmountToCharge()}
          />
        </WillCreationLayoutWrapper>
      }
    </>
  );
};

export default PaymentsHome;
