import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
  addToUserStatus,
  setAuthFailed,
  setConfigLoadingStatus,
  setContacts,
  setContent,
  setCurrentUserPlans,
  setProfile,
  setPaymentHistory,
  setYellowPlusCart,
  setYellowPlusConfig,
  UserStatus,
  updateYellowPaymentSummaryData,
  updateSendWillRequest,
  updatePreviousWillRecipients,
  updateWillRecipients,
  setLoggedOutConfig,
  // setShowGuardianSection,
} from './slice';
import {
  AccessRole,
  Asset,
  Contact,
  Content,
  KycStatus,
  Liability,
  UnloggedInUserSessionData,
  UserProfile,
  Will,
  WillRecipient, 
  WillRecipientInput
} from '../../utils/types';
import {
  FETCH_CONFIG,
  FETCH_CONTACTS,
  FETCH_CONTENT,
  FETCH_CURRENT_USER_PLANS,
  FETCH_PLAN_BY_USER_INFO,
  FETCH_PLAN_BY_USER_INFO_DIRECT,
  // INITIATE_CART_PAYMENT,
  FETCH_USER_PROFILE,
  GET_PAYMENT_HISTORY,
  INITIATE_CART_PAYMENT,
  GET_WILL_MAIL_RECIPIENTS,
  ADD_WILL_MAIL_RECIPIENT,
  FETCH_LOGGED_OUT_CONFIG,
  FETCH_WILL_RECIPIENTS,
  ADD_WILL_RECIPIENT,
} from 'common/queries';
// import { runMutation, runQuery } from 'utils/graphQL';
import { runMutation, runQuery } from 'utils/graphQL';
import { showToast, ToastType } from '../toast/slice';
import {
  selectConfig,
  selectSendWillToRequest,
  selectUnloggedInUserSessionData,
  selectUserProfile,
  selectUserStatus,
  selectYellowPaymentSummaryData,
  selectYellowPlusCartData,
  selectYellowPlusDefaultPlansConfig,
  selectYellowPlusPlansForAgeLocationConfig,
} from './selectors';
// import { selectYellowPlusCartData } from './selectors';

import { AddOn, Plan, PlanData, PlanDataRequestResponse } from '../../utils/choosePlanTypes';
import { CartAddOnForOthers, CartData, CartDataArr, CouponInput } from 'utils/cartDataTypes';
import { PaymentHistoryResponse } from '../../utils/paymentHistoryTypes';
import { checkBasicConditionsToGenerateWill, getWillStatus } from 'utils';
import {
  CurrentUserPlansResponse,
  CurrentUserPlansResponseNew,
} from '../../utils/currentUserPlansTypes';
import { InitiateCartPaymentResponseData } from '../../utils/paymentSummaryTypes';
import { cloneDeep } from '@apollo/client/utilities';
import { Mixpanel } from 'utils/mixpanel';
import { WillMailRecipient, WillMailRecipientInput } from '../../utils/willmailrecipienttypes';
import { ConfigResponse } from 'utils/config/interfaces';

function* fetchUserProfile(action?: { meta?: { resolve?: any; reject?: any } }) {
  yield put(addToUserStatus(UserStatus.ACTION_SET_PROFILE_NOT_LOADED));
  const resolve = action?.meta?.resolve;
  const reject = action?.meta?.reject;
  try {
    const {
      data: {
        me: {
          profile,
          userAccessRole,
          contacts,
          remainingProfileFields,
          assets,
          liabilities,
          will,
          createdAt,
        },
      },
    }: {
      data: {
        me: {
          profile: UserProfile;
          userAccessRole: AccessRole;
          contacts: Array<Contact>;
          will: Will;
          assets: Array<Asset>;
          liabilities: Array<Liability>;
          remainingProfileFields: Array<string>;
          createdAt: string;
        };
      };
    } = yield call(() => runQuery({ query: FETCH_USER_PROFILE }));

    // tell mixpanel that the user is logged in
    profile && Mixpanel.setLoggedInUserId(profile.documentId);

    const profileToSet = {
      profile,
      accessRole: userAccessRole,
      contacts,
      will,
      assets,
      liabilities,
      remainingProfileFields: remainingProfileFields || [],
      createdAt,
    };

    yield put(setProfile(profileToSet));

    if (remainingProfileFields.length > 0) {
      yield put(addToUserStatus(UserStatus.PROFILE_INCOMPLETE));
    }

    if (will === null) {
      yield put(addToUserStatus(UserStatus.WILL_DOC_NOT_CREATED));
    }

    const config: ConfigResponse = yield select(selectConfig);

    if (will?.paymentInfo === undefined || will?.paymentInfo === null) {
      // if config is not there, then just set payment incomplete,
      // if config is there, then check the amount and set payment incomplete
      if (config === undefined || config === null) {
        yield put(addToUserStatus(UserStatus.PAYMENT_INCOMPLETE));
      } else if (config.configs.mobilePlans['Premium Will'].grossAmount !== 0) {
        yield put(addToUserStatus(UserStatus.PAYMENT_INCOMPLETE));
      }
    }

    if (!getWillStatus(will) || !checkBasicConditionsToGenerateWill(will)) {
      yield put(addToUserStatus(UserStatus.WILL_CREATION_INCOMPLETE));
    }

    if (profile?.wills.length > 1) {
      yield put(addToUserStatus(UserStatus.FIRST_WILL_COMPLETED));
    }

    // the ordering of when we set the PROFILE_LOADED is very important
    // make sure we set status for every related item before setting this
    yield put(addToUserStatus(UserStatus.PROFILE_LOADED));

    if (profile) {
      // we want to set user properties in Mixpanel
      if (will) {
        Mixpanel.setUserProperties({
          'Number of assets added': will.assets?.length,
          'Number of liabilities added': will.liabilities?.length,
          'KYC Type':
            profile.kycDetails?.kycStatus === KycStatus.DONE
              ? profile.kycDetails?.kycType
              : 'NOT DONE',
        });
      }
    }
    if (resolve) yield call(resolve, profileToSet);
  } catch (e) {
    console.log('Error Fetching User Profile', e);
    yield put(setAuthFailed());
    if (reject) yield call(reject, e);
  }
}

function* fetchContent() {
  try {
    const {
      data: { content },
    }: {
      data: { content: Content };
    } = yield call(() => runQuery({ query: FETCH_CONTENT }));
    yield put(
      setContent({
        content,
      }),
    );
  } catch (error) {
    console.log('Error Fetching Content ', error);
  }
}

function* fetchYellowPlusConfig() {
  try {
    const unloggedInUserSessionData: Partial<UnloggedInUserSessionData> = yield select(
      selectUnloggedInUserSessionData,
    );
    const hasData =
      (unloggedInUserSessionData?.isNri ?? undefined) !== undefined &&
      (unloggedInUserSessionData?.dateOfBirth ?? undefined) !== undefined;
    let response: Partial<PlanDataRequestResponse> = yield call(() =>
      runQuery({
        query: hasData ? FETCH_PLAN_BY_USER_INFO : FETCH_PLAN_BY_USER_INFO_DIRECT,
        ...(hasData
          ? {
              input: {
                key: 'input',
                value: {
                  isNRI: unloggedInUserSessionData?.isNri,
                  dob: unloggedInUserSessionData?.dateOfBirth,
                },
              },
            }
          : {}),
      }),
    );
    yield put(setYellowPlusConfig(response?.data?.fetchPlanByUserInfo || {}));
    // yield put(addToUserStatus(UserStatus.YELLOW_PLUS_CONFIG_LOADED));
  } catch (err) {}
}

function* fetchYellowPlusCart() {
  try {
    const userProfile: Partial<UserProfile> = yield select(selectUserProfile);
    const unloggedInUserSessionData: Partial<UnloggedInUserSessionData> = yield select(
      selectUnloggedInUserSessionData,
    );
    const plansData: Partial<PlanData> = yield select(selectYellowPlusPlansForAgeLocationConfig);
    let plansDefaultDataTemp: Partial<PlanData> = yield select(selectYellowPlusDefaultPlansConfig);
    if (!plansDefaultDataTemp) {
      yield fetchConfig();
    }
    let plansDefaultData: Partial<PlanData> =
      plansDefaultDataTemp || (yield select(selectYellowPlusDefaultPlansConfig));

    let planInfo: Partial<Plan> | undefined =
      plansData?.plans?.find((plan) => plan?.id === unloggedInUserSessionData.chosenPlanId) ||
      plansDefaultData?.plans?.find((plan) => plan?.id === unloggedInUserSessionData.chosenPlanId);
    const addOnInfo: Partial<AddOn>[] | undefined = plansData?.addOns?.filter(
      (add_on: Partial<AddOn>) =>
        add_on?.id && unloggedInUserSessionData?.chosenAddOns?.includes(add_on?.id),
    );
    const addOnForOthersInfo: Partial<CartAddOnForOthers>[] | undefined = [];
    const yellowCartResponse: CartData = {
      data: [
        {
          name: userProfile?.fullName,
          email: userProfile?.email,
          phone_number: userProfile?.phoneNumber,
          plan_info: {
            ...(planInfo || {}),
          },
          add_ons: [...(addOnInfo || [])],

          // Have to verify is it right?
          add_ons_for_others: [...(addOnForOthersInfo || [])],
        },
      ],
    };
    yield put(setYellowPlusCart(yellowCartResponse));
  } catch (err) {}
}

function fixFetchPlansFieldName(
  response: CurrentUserPlansResponseNew | CurrentUserPlansResponse,
): CurrentUserPlansResponse {
  return {
    // @ts-ignore
    data: { fetchPlans: response?.data?.fetchCurrentUserPlans || response?.data?.fetchPlans },
  };
}

function* fetchCurrentUserPlans(action: any) {
  const resolve = action?.meta?.resolve;
  const reject = action?.meta?.reject;
  try {
    yield put(addToUserStatus(UserStatus.ACTION_SET_YELLOW_PLUS_PLAN_LOADING));
    const response: CurrentUserPlansResponseNew = yield call(() =>
      runQuery({ query: FETCH_CURRENT_USER_PLANS }),
    );
    yield put(setCurrentUserPlans(fixFetchPlansFieldName(response)));
    yield put(addToUserStatus(UserStatus.YELLOW_PLUS_PLAN_LOADED));
    if (resolve) yield call(resolve(resolve));
  } catch (err) {
    console.log('Error fetching current user plans');
    if (reject) {
      yield call(reject(err));
    }
  }
}

function* fetchLoggedOutConfig() {
  try {
    const loggedOutConfig: CurrentUserPlansResponseNew = yield call(() =>
      runQuery({ query: FETCH_LOGGED_OUT_CONFIG }),
    );
    yield put(setLoggedOutConfig(loggedOutConfig));
  } catch (err) {
    console.log('Error fetching logged out config');
  }
}

function* getPaymentHistory() {
  try {
    const response: PaymentHistoryResponse = yield call(() =>
      runQuery({ query: GET_PAYMENT_HISTORY }),
    );
    yield put(setPaymentHistory(response));
  } catch (err) {
    console.log('Error fetching current user plans');
  }
}

function* fetchConfig() {
  try {
    const userStatus: UserStatus[] = yield select(selectUserStatus);
    if (!userStatus.includes(UserStatus.LOGGED_IN)) return;

    yield put(setConfigLoadingStatus({ success: false, isLoading: true }));
    const {
      data: { getYellowAppConfigs: configString },
    }: { data: { getYellowAppConfigs: string } } = yield call(() =>
      runQuery({ query: FETCH_CONFIG }),
    );
    const config = JSON.parse(configString) as ConfigResponse;
    yield put(
      setConfigLoadingStatus({
        success: true,
        isLoading: false,
        getYellowAppConfigs: config,
      }),
    );

    if (config.configs.mobilePlans['Premium Will'].grossAmount === 0)
      yield put(addToUserStatus(UserStatus.ACTION_SET_PAYMENT_COMPLETED));
    yield put(addToUserStatus(UserStatus.CONFIG_LOADED));
  } catch (error) {
    console.log(error);
    yield put(
      showToast({
        type: ToastType.ERROR,
        message: `Cannot fetch asset config ${error}`,
      }),
    );
    yield put(setConfigLoadingStatus({ success: false, isLoading: false }));
  }
}

function* fetchContacts() {
  try {
    const userStatus: UserStatus[] = yield select(selectUserStatus);
    if (!userStatus.includes(UserStatus.LOGGED_IN)) return;

    const {
      data: {
        me: { contacts },
      },
    }: { data: { me: { contacts: Contact[] } } } = yield call(() =>
      runQuery({ query: FETCH_CONTACTS }),
    );
    yield put(setContacts(contacts));
  } catch (error) {
    console.log(error);
    yield put(
      showToast({
        type: ToastType.ERROR,
        message: `Cannot fetch asset config ${error}`,
      }),
    );
    yield put(setConfigLoadingStatus({ success: false, isLoading: false }));
  }
}

// function* fetchGuardianShowStatusFromLocalStorage() {
//   let getGuardianShowValue = JSON.parse(localStorage.getItem('showGuardianSection') as string);
//   if (getGuardianShowValue) {
//     yield put(setShowGuardianSection(getGuardianShowValue.showGuardianSection));
//   }
// }

export function* contentSaga() {
  yield takeLatest('FETCH_CONTENT', fetchContent);
}

export function* initiateCart() {
  const yellowPlusCartData: CartData = yield select(selectYellowPlusCartData);
  const cart: CartDataArr | undefined = yellowPlusCartData?.data?.[0];
  const couponInput: Partial<CouponInput> | undefined = yellowPlusCartData?.couponInput;
  const value = {
    cartItemsInput: [
      ...(cart?.plan_info?.id
        ? [
            {
              itemId: cart?.plan_info?.id,
              itemType: 'PLAN',
            },
          ]
        : []),
      // @ts-ignore
      ...(cart?.add_ons || []).filter(Boolean).map((add_on: AddOn) => ({
        // @ts-ignore
        itemId: add_on.id,
        // @ts-ignore
        itemType: 'ADD_ON',
      })),
      ...(cart?.add_ons_for_others || [])
        .filter(Boolean)
        .map((add_on: Partial<CartAddOnForOthers>) => ({
          itemId: add_on?.id,
          itemType: 'ADD_ON_FOR_FAMILY',
          beneficiaryInfo: {
            name: add_on?.fullName,
            dateOfBirth: add_on?.dateOfBirth,
            phoneNumber: add_on?.phoneNumber,
            email: add_on?.email,
            gender: add_on?.gender,
            relationship: add_on?.relationship,
            isNRI: !!add_on?.isNRI,
          },
        })),
    ],
    couponInput,
  };
  // @ts-ignore
  let cartResponse: InitiateCartPaymentResponseData = cloneDeep(
    // @ts-ignore
    yield select(selectYellowPaymentSummaryData),
  ) as unknown as InitiateCartPaymentResponseData;
  // @ts-ignore
  try {
    cartResponse = yield call(() =>
      // @ts-ignore
      runMutation({
        // @ts-ignore
        mutation: INITIATE_CART_PAYMENT,
        // @ts-ignore
        variables: { key: 'input', value },
        // @ts-ignore
      }),
    );
  } catch (err) {
    // @ts-ignore
    switch (err.toString()) {
      case 'ApolloError: This coupon code is not valid for the selected plan.':
      case 'ApolloError: Coupon code expired!':
      case 'ApolloError: Invalid coupon code. Please check again!': {
        // @ts-ignore
        if (cartResponse?.data?.initiateCartPayment) {
          // @ts-ignore
          cartResponse.data.initiateCartPayment.couponDiscountAmount = -1;
          // @ts-ignore
          cartResponse.data.initiateCartPayment.couponApplicationError = err.toString();
        }
        break;
      }
    }
  }
  yield put(
    updateYellowPaymentSummaryData(cartResponse as unknown as InitiateCartPaymentResponseData),
  );
}

export function* sendYellowPolicy() {
  try {
    const value: WillMailRecipientInput = yield select(selectSendWillToRequest);
    const yellowPolicy: WillMailRecipient = yield call(() =>
      // @ts-ignore
      runMutation({
        // @ts-ignore
        mutation: ADD_WILL_MAIL_RECIPIENT,
        // @ts-ignore
        variables: { key: 'input', value },
        // @ts-ignore
      }),
    );
    yield put(updateSendWillRequest(yellowPolicy as unknown as WillMailRecipient));
  } catch {}
}

export function* getWillMailPreviousRecipients() {
  try {
    const previousRecipients: Partial<WillMailRecipient>[] = yield call(() =>
      // @ts-ignore
      runQuery({
        // @ts-ignore
        query: GET_WILL_MAIL_RECIPIENTS,
      }),
    );
    yield put(
      updatePreviousWillRecipients(previousRecipients as unknown as Partial<WillMailRecipient>[]),
    );
  } catch {}
}

export function* getWillRecipients() {
  try {
    const {
      data: {
        fetchWillRecipients: currentRecipients,
      }
    }: {
      data: {
        fetchWillRecipients: WillRecipient;
      }
    } = yield call(() =>
      // @ts-ignore
      runQuery({
        // @ts-ignore
        query: FETCH_WILL_RECIPIENTS,
      }),
    );
    console.log("Current Recipients: ",currentRecipients.recipients);
    yield put(
      updateWillRecipients(currentRecipients.recipients as unknown as WillRecipientInput[]),
    );

  } catch {}

}

export function* addWillRecipients(action: any) {
  // const resolve = action?.meta?.resolve;
  const reject = action?.meta?.reject;
  try {
    const value: WillRecipientInput = action.payload;
    // @ts-ignore
    const tempResponse: WillRecipient = yield call(() =>
      runMutation({
        mutation: ADD_WILL_RECIPIENT,
        variables: { key: 'input', value },
      }),
    );

    //yield put to call fetchWillRecipients
    yield put({ type: 'FETCH_WILL_RECIPIENTS' });

    // getWillRecipients();
  } catch (error) {
    console.log('Error Fetching User Profile', error);
    if (reject) yield call(reject, error);
  }
}

export default function* profileSaga() {
  yield takeLatest('FETCH_USER_PROFILE', fetchUserProfile);
  yield takeLatest('FETCH_CURRENT_USER_PLANS', fetchCurrentUserPlans);
  yield takeLatest('FETCH_LOGGED_OUT_CONFIG', fetchLoggedOutConfig);
  yield takeLatest('FETCH_YELLOW_PLUS_CONFIG', fetchYellowPlusConfig);
  yield takeLatest('FETCH_YELLOW_PLUS_CART', fetchYellowPlusCart);
  yield takeLatest('user/setAuthLoadingComplete', fetchConfig);
  yield takeLatest('FETCH_CONTACTS', fetchContacts);
  yield takeLatest('INITIATE_CART', initiateCart);

  yield takeLatest('GET_PAYMENT_HISTORY', getPaymentHistory);

  yield takeLatest('GET_WILL_MAIL_RECIPIENTS', getWillMailPreviousRecipients);
  yield takeLatest('SEND_WILL', sendYellowPolicy);

  yield takeLatest('FETCH_WILL_RECIPIENTS', getWillRecipients);
  yield takeLatest('ADD_WILL_RECIPIENT', addWillRecipients);
}
