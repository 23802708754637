import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from 'styles/theme';
import { HTMLButtonTypes } from 'utils/types';
import { breakpoints } from '../../../styles/Breakpoints';

type StyledButtonProps = {
  backgroundColor: string;
  fontColor: string;
  hoverColor: string;
  hoverBackgroundColor: string;
  lessThanSmallPageBottom?: boolean;
};

const StyledButton = styled.button<StyledButtonProps>`
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  background-color: ${(props) => props.backgroundColor};
  height: 50px;
  // height: ${(props) => (props.lessThanSmallPageBottom ? '50px !important' : '')};
  // height: 50px !important;
  font-size: 16px;
  transition: all 0.3s ease-out;
  .buttonText {
    padding: 0px 8px;
    color: ${(props: StyledButtonProps) => props.fontColor};
    transition: all 0.3s ease-out;
  }
  :hover {
    background-color: ${(props: StyledButtonProps) => props.hoverBackgroundColor};
    height: 50px;
    // height: 50px !important;
    font-size: 16px;
  }
  :hover .buttonText {
    color: ${(props: StyledButtonProps) => props.hoverColor};
  }

  :disabled {
    // background: #E3E4E7
    opacity: 0.2;
    cursor: not-allowed;
  }
  
  @media screen and (max-width: ${breakpoints.md}) {
    ${(props) =>
      props.lessThanSmallPageBottom
        // ? ' position: fixed; bottom:0; left:0; right:0; width:100vw; border-radius:0 0 12px 12px;'
        ? 'border-radius:12px;'
        : ''}
  }
`;

const CustomButton = ({
  type,
  handle,
  text,
  onClick = null,
  disabled,
  className = '',
  style = {},
  backgroundColor = theme.primaryColor,
  fontColor = theme.white,
  hoverColor = theme.white,
  hoverBackgroundColor = theme.primaryColor,
  form,
  lessThanSmallPageBottom,
}: {
  type?: HTMLButtonTypes;
  handle?: string;
  text: string;
  onClick?: Function | null;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties | undefined;
  backgroundColor?: string;
  fontColor?: string;
  hoverColor?: string;
  hoverBackgroundColor?: string;
  form?: string;
  lessThanSmallPageBottom?: boolean;
}) => {
  const clickedOnText = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    if (onClick) onClick();
  };
  return (
    <StyledButton
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      className={className}
      style={style}
      type={type}
      onClick={() => (onClick ? onClick() : null)}
      disabled={disabled}
      hoverColor={hoverColor}
      hoverBackgroundColor={hoverBackgroundColor}
      form={form}
      lessThanSmallPageBottom={lessThanSmallPageBottom}
    >
      {handle ? (
        <Link to={handle} className='buttonNavLink'>
          <span className='buttonText'>{text}</span>
        </Link>
      ) : (
          <span onClick={clickedOnText} className='buttonText'>
            {text}
          </span>
      )}
    </StyledButton>
  );
};

export default CustomButton;
