import client from 'utils/apollo';
import { DocumentNode } from 'graphql';

export type MutationVariables = {
  key: string; // mutation input key.
  value: any;
};

export const runQuery = async ({
  query,
  input = {} as MutationVariables,
}: {
  query: DocumentNode;
  input?: MutationVariables;
}): Promise<any> =>
  await client.query({
    query: query,
    variables: {
      [input.key]: input.value,
    },
  });

export const runMutation = async ({
  mutation,
  variables,
}: {
  mutation: DocumentNode;
  variables: MutationVariables;
}): Promise<any> =>
  await client.mutate({
    mutation,
    variables: {
      [variables.key]: variables.value,
    },
  });
