import { Avatar, Grid, IconButton } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { selectUserProfile, selectUserStatus } from 'store/user/selectors';
import styled from 'styled-components';
import { styled as styledMui } from '@mui/system';

// Menu Items assets.
import myaccount from 'assets/svg/img_myaccountmenuitemicon.svg';
import home from 'assets/svg/img_side_menu_home.svg';
import downloadIcon from 'assets/svg/img_download_side_menu_icon.svg';
// import kyc from 'assets/svg/menuItem-kyc.svg';
import privacyPolicy from 'assets/svg/menuItem-privacyPolicy.svg';
import terms from 'assets/svg/menuItem-terms.svg';
import logout from 'assets/svg/menuItem-logout.svg';
import contact from 'assets/svg/menuItem-contact.svg';
import img_arrowdown from 'assets/svg/img_arrowdown.svg';
import img_arrowup from 'assets/svg/img_arrowup.svg';
import img_sidemenumedicalcertificate from 'assets/svg/img_sidemenumedicalcertificate.svg';
import img_sidemenuinstructionboolet from 'assets/svg/img_sidemenuinstructionboolet.svg';

// import EditProfile from 'Pages/UserAccount/EditProfile';
import { useNavigate } from 'react-router-dom';
import MuiDrawer from 'common/Reusables/Drawer';
import { Accordion, AccordionSummary, AccordionDetails } from '../../common/Accordion';
import ContactUsHome from 'Pages/ContactUs';
import { addToUserStatus, UserStatus } from 'store/user/slice';
import { Pages } from 'routes';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MyAccountPagesSideMenu from '../../common/MyAccountPagesSideMenu';
import medicalFitnessCertificate from 'assets/Medical-Fitness-Certificate.pdf';
import yellowBooklet from 'assets/Yellow-Instruction-Booklet.pdf';
import { Mixpanel } from 'utils/mixpanel';
import CloseIcon from '@mui/icons-material/Close';

const MenuWrapper = styled.div`
  padding: 30px 20px 0px;
  width: ${(props: { width: string }) => props.width};
  position: relative;
  height: 100%;
  /* transition: all 0.5s ease-in; */

  .email {
    color: #787c85;
    margin: 3px 0px;
  }

  .menuItemsHolder {
    margin-top: 40px;
    .menuItem {
      align-items: center;
      cursor: pointer;
      margin: 20px auto;
      .label {
        padding-left: 10px;
      }
    }
  }

  .logout {
    display: flex;
    align-items: center;
    margin-bottom: 100px;
    .label {
      margin-left: 20px;
    }
  }
`;

export const AccordionStyled = styledMui(Accordion)({
  border: 'none',
  boxShadow: 'none',
  margin: '0px',
  padding: '0px',
  borderRadius: '12px',
  boxSizing: 'border-box',
  backgroundColor: 'transparent',
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    margin: '0px',
    padding: 0,
    boxSizing: 'border-box',
  },
  '& .MuiAccordian-root': {
    backgroundColor: 'transparent',
    margin: '0px',
    padding: 0,
    boxSizing: 'border-box',
  },
  '& .MuiAccordianSummary-content': {
    backgroundColor: 'transparent',
    margin: '0px',
    padding: '0px',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%',
  },
});

export const AccordionSummaryStyled = styledMui(AccordionSummary)({
  border: 'none',
  boxShadow: 'none',
  margin: '0px',
  padding: '0px',
  boxSizing: 'border-box',
  backgroundColor: 'transparent',

  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    margin: '0px',
    padding: 0,
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiAccordianSummary-root': {
    backgroundColor: 'transparent',
    margin: '0px',
    padding: 0,
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiAccordianSummary-content': {
    backgroundColor: 'transparent',
    margin: '0px',
    padding: '0px',
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiButtonBase-root': {
    backgroundColor: 'transparent',
    margin: '0px',
    padding: 0,
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiAccordian-root': {
    backgroundColor: 'transparent',
    margin: '0px',
    padding: 0,
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%',
  },
  '& .MuiAccordionSummary-content': { margin: '0px' },
});

const MuiDrawerMyAccountStyled = styled(MuiDrawer)({});

export const AccordionDetailsStyled = styledMui(AccordionDetails)({
  border: 'none',
  boxShadow: 'none',
  margin: '0px',
  padding: '0px',
  borderRadius: '12px',
  boxSizing: 'border-box',
  backgroundColor: 'transparent',
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
  },
  '& .MuiAccordian-root': {
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
  },
});

const MenuItem = ({
  icon,
  title,
  description,
  onClick,
  rhsMotif,
  rhsMotifWeight = 1,
}: {
  icon: string;
  title: string;
  description?: string;
  onClick?: Function;
  rhsMotif?: ReactNode;
  rhsMotifWeight?: number;
}) => (
  <div
    className={`w-100 flex flex-row ${
      description ? 'items-start' : 'items-center'
    } justify-center gap-16px p-16px py-24px`}
    onClick={() => onClick && onClick()}
  >
    {/*// @ts-ignore */}
    <div className='menuIcon'>
      <img src={icon} alt='Menu Item Icon' className='w-100 h-100 object-fit-contain' />
    </div>
    {/*// @ts-ignore */}
    <div style={{ flex: 6, width: 'auto', height: '100%' }}>
      <div
        className={`w-100 h-100 flex flex-col gap-8px ${
          description ? 'items-start' : 'items-start'
        } justify-center`}
      >
        <span style={{ color: 'rgba(0, 0, 0, 0.87)' }}>{title}</span>
        {description ? (
          <>
            <span className={'text-darkergray'}>{description}</span>
          </>
        ) : null}
      </div>
    </div>
    {/*// @ts-ignore */}
    <div style={{ flex: 1, width: 'auto', height: '100%' }}>
      <div className={'w-100 h-100'}>{rhsMotif || <div className={'w-100 h-100'} />}</div>
    </div>
  </div>
);

const DownloadableResourcesMenuItem = ({ hideDrawer }: { hideDrawer?: () => unknown }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <AccordionStyled expanded={expanded}>
      <AccordionSummaryStyled onClick={() => setExpanded(!expanded)}>
        <Grid container xs={12} spacing={0}>
          <MenuItem
            icon={downloadIcon}
            title={'Downloadable Resources'}
            rhsMotif={
              <img
                src={expanded ? img_arrowup : img_arrowdown}
                alt={expanded ? 'Close' : 'Open'}
                className='icon'
              />
            }
          />
        </Grid>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <Grid container xs={12} spacing={0}>
          <a href={medicalFitnessCertificate} target='_blank' download>
            <MenuItem
              icon={img_sidemenumedicalcertificate}
              title={'Medical Certificate'}
              description={
                'An editable template to be signed by your doctor may be used to certify that you were of sound mind at the time of signing your Will'
              }
              onClick={() => {
                hideDrawer && hideDrawer();
              }}
              rhsMotif={<img src={downloadIcon} alt={'Download'} className='icon' />}
            />
          </a>
          <a href={yellowBooklet} target='_blank' download>
            <MenuItem
              icon={img_sidemenuinstructionboolet}
              title={'Instruction Booklet'}
              description={
                'This booklet contains step-by-step instructions for how to make your Will legally valid'
              }
              onClick={() => {
                hideDrawer && hideDrawer();
              }}
              rhsMotif={<img src={downloadIcon} alt={'Download'} className='icon' />}
            />
          </a>
        </Grid>
      </AccordionDetailsStyled>
    </AccordionStyled>
  );
};

const Menu = ({ hideDrawer }: { hideDrawer?: () => unknown }) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const userProfile = useAppSelector(selectUserProfile);
  const userStatus = useAppSelector(selectUserStatus);
  const unloggedInUserSessionData = useAppSelector((state) => state.user.unloggedInUserSessionData);

  // const [showEditProfile, setShowEditProfile] = useState(false);
  const [showContactUsScreen, setShowContactUsScreen] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
  const [myAccountSideMenuOpen, setMyAccountSideMenuOpen] = useState<boolean>(false);

  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const menuItemsData = [
    {
      icon: home,
      title: 'My Home',
      action: () => {
        //Mixpanel.track('Home click', { from: `Hamburger menu` });
        if (userProfile?.externalVendor === undefined || userProfile.externalVendor === null) {
          navigate(Pages.DASHBOARD);
        } else {
          navigate(Pages.VENDOR_DASHBOARD);
        }
      },
    },
    {
      icon: myaccount,
      title: 'My Account',
      action: () => {
        Mixpanel.track('My Account click', { from: `Hamburger menu` });
        // if (lessThanSmall) setMyAccountSideMenuOpen(true);
        // else navigate(Pages.EDIT_PROFILE);
        navigate(Pages.EDIT_PROFILE);
      },
    },
    {
      type: 'downloadable-resources',
    },
    // {
    //   icon: kyc,
    //   title: 'KYC Details',
    //   action: () => navigate(Pages.KYC),
    // },
    // {
    //   icon: bookmarks,
    //   title: 'My Bookmarks',
    //   action: () => ({}),
    // },
    //   Others
    {
      icon: contact,
      title: 'Contact Us',
      action: () => setShowContactUsScreen(true),
    },
    {
      icon: privacyPolicy,
      title: 'Privacy Policy',
      action: () => navigate(Pages.PRIVACY_POLICY),
    },
    {
      icon: terms,
      title: 'Terms of Services',
      action: () => navigate(Pages.LEGAL),
    },
  ];

  const handleLogout = () => {
    localStorage.clear();
    dispatch({
      type: 'LOGOUT_ATTEMPT',
    });
    dispatch(addToUserStatus(UserStatus.LOGGED_OUT));
    window.Intercom && window.Intercom('shutdown');
    Mixpanel.setUserLoggedOut();
    if (unloggedInUserSessionData.postLoginUrl === undefined) {
      window.location.href = '/';
    } else {
      navigate(Pages.YELLOW_PLUS);
    }
  };

  useEffect(() => {
    if (
      userStatus.includes(UserStatus.PROFILE_LOADED) &&
      userStatus.includes(UserStatus.PROFILE_INCOMPLETE)
    ) {
      setIsOnboarding(true);
    }
  }, [userStatus]);

  return (
    <MenuWrapper width={'auto'}>
      <>
        <div
          className={
            'flex flex-col items-start justify-between min-h-100 w-100 max-w-100 overflow-y-scroll hide_scrollbars border-box'
          }
        >
          <div className={'flex flex-col items-start justify-start'}>
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              // justifyContent='space-between'
              // spacing={1}
              alignItems='center'
              className={isOnboarding ? 'userInfo cursor-default' : 'userInfo cursor-default'}
              // className=`userInfo ${isEditProfileDisabled ? cursor-pointer}`
              // onClick={() => !isOnboarding && setShowEditProfile(true)}
            >
              <Grid container flexDirection={'row'} justifyContent='space-between'>
                <Grid item container flexDirection={'row'} style={{ width: 'auto' }}>
                  <Grid item lg={2} md={2} sm={2}>
                    <Avatar
                      src={(userProfile && userProfile.profilePicture) || ''}
                      sx={{ height: '50px', width: '50px' }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={9}
                    md={9}
                    sm={2}
                    sx={{ paddingLeft: lessThanSmall ? '1rem' : '1.5rem' }}
                  >
                    <p className='font-semi-bold' style={{ width: 'max-content' }}>
                      {userProfile?.fullName || 'Your Profile'}
                    </p>
                    {userProfile?.email && <p className='email'>{userProfile.email}</p>}
                  </Grid>
                </Grid>
                <Grid item lg={1} md={1} sm={1}>
                  <IconButton onClick={hideDrawer} aria-label='Close'>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <div className='menuItemsHolder cursor-pointer'>
              {menuItemsData
                .slice(0, 2)
                // .slice(0, 3)
                .map((item) => {
                  switch (item.type) {
                    case 'downloadable-resources':
                      return <DownloadableResourcesMenuItem hideDrawer={hideDrawer} />;
                    default:
                      return (
                        <MenuItem
                          // @ts-ignore
                          icon={item.icon}
                          // @ts-ignore
                          title={item.title}
                          // @ts-ignore
                          onClick={() => {
                            hideDrawer && hideDrawer();
                            item?.action?.();
                          }}
                        />
                      );
                  }
                })}
            </div>
            <div className='menuItemsHolder cursor-pointer'>
              <h2 className='subText'>Other</h2>
              {menuItemsData.slice(3, 6).map((item) => (
                // @ts-ignore
                <MenuItem icon={item.icon} title={item.title} onClick={() => item.action()} />
              ))}
            </div>
          </div>
          <div className='cursor-pointer logout' onClick={() => handleLogout()}>
            <MenuItem icon={logout} title={'Logout'} />
          </div>
        </div>
      </>

      <MuiDrawer
        open={showContactUsScreen}
        width={'min(100%, 500px)'}
        hideDrawer={() => setShowContactUsScreen(false)}
      >
        <ContactUsHome closeIconAction={() => setShowContactUsScreen(false)} />
      </MuiDrawer>
      <MuiDrawerMyAccountStyled
        width={'100%'}
        open={myAccountSideMenuOpen}
        hideDrawer={() => setMyAccountSideMenuOpen(false)}
      >
        <MyAccountPagesSideMenu closeIconAction={() => setMyAccountSideMenuOpen(false)} />
      </MuiDrawerMyAccountStyled>
    </MenuWrapper>
  );
};

export default Menu;
