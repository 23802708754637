import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { PrivateRoute } from 'common/PrivateRoute';
import GlobalStyle from 'styles/GlobalStyles';
import { Suspense, useEffect, lazy } from 'react';
import Toast from 'common/Toast';
import { useAppSelector, useTypedDispatch } from 'store/hooks';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import ScrollToTop from 'common/ScrollToTop';
import { /*MOBILE_ROUTES,*/ Pages, pagesList } from 'routes';

const Home = lazy(() => import('Pages/Home'));
const RegistrationAndLogin = lazy(() => import('Pages/Authentication/RegistrationAndLogin'));
const NewPayments = lazy(() => import('Pages/NewPayments'));
const LegalTerms = lazy(() => import('Pages/LegalTerms'));
const PrivacyPolicy = lazy(() => import('Pages/PrivacyPolicy'));
const KYCVerification = lazy(() => import('Pages/KYC'));
const MyWill = lazy(() => import('Pages/MyWill/NewMyWill'));
const ShippingFlow = lazy(() => import('Pages/MyWill/ShippingFlow'));
const OnBoarding = lazy(() => import('Pages/Authentication/OnBoarding'));
const YellowPlusPlans = lazy(() => import('Pages/YessPlus/YellowPlusPlans'));
const YessPlan = lazy(() => import('Pages/YessPlus/Plans'));
const CongratulationsPlus = lazy(
  () => import('Pages/YessPlus/Congratulations/CongratulationsPlus'),
);
const WillEditor = lazy(() => import('Pages/MyWill'));
const Account = lazy(() => import('Pages/UserAccount'));
const SignupAndConfirm = lazy(() => import('Pages/YessPlus/SignupAndConfirm/SignupAndConfirm'));
const YellowPlus = lazy(() => import('Pages/YessPlus/YellowPlus'));
const PaymentSummary = lazy(() => import('Pages/YessPlus/PaymentSummary/paymentSummary'));
const EditProfile = lazy(() => import('Pages/UserAccount/EditProfile'));
const DesktopOnly = lazy(() => import('./Pages/DesktopOnly'));
const Dashboard = lazy(() => import('Pages/Dashboard/NewDashboard'));
const VendorDashboard = lazy(() => import('Pages/VendorDashboard/VendorDashboard'));

// Yellow Plus Cart
// import YessCartCard from 'Pages/YessPlus/YessCart/YessCartCard';

import Loading from 'common/Loading';
// import QuickCart from 'Pages/YessPlus/QuickCart';
import CustomAuth from './CustomAuth';
import { useVendor } from 'common/useVendor';
import { Intercom } from 'utils/intercom';
import { selectUserStatus } from 'store/user/selectors';
import { UserStatus } from 'store/user/slice';
import { DeleteAccount } from 'Pages/UserAccount/DeleteAccount';

function App() {
  const dispatch = useTypedDispatch();
  const location = useLocation();
  const { isAcko } = useVendor();
  const userStatus = useAppSelector(selectUserStatus);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch({ type: 'INIT_SAGA_HANDLER' });
    if (navigator.userAgent.indexOf('iPhone') > -1) {
      document
        ?.querySelector('[name=viewport]')
        ?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
    }
  }, []);

  useEffect(() => {
    if (
      userStatus.includes(UserStatus.LOGGED_OUT) ||
      (userStatus.includes(UserStatus.PROFILE_LOADED) && !isAcko)
    ) {
      setTimeout(() => {
        Intercom.showLauncher();
      }, 2000);
    }
  }, [isAcko, userStatus]);

  useEffect(() => {
    if (userStatus.includes(UserStatus.PROFILE_LOADED) && !isAcko) {
      Intercom.showIntercomOnAllPagesIfSessionExpired();
    }
  }, [location, userStatus, isAcko]);

  useEffect(() => {
    const trackedPageName = pagesList.find((page) => page.startsWith(location.pathname));
    console.log({ searchParams });
    dispatch({
      type: 'LOCATION_CHANGED',
      payload: {
        pathName: location.pathname,
        trackedPageName,
        params: Object.fromEntries(searchParams),
        // params: Array.from(searchParams)
      },
    });
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <GlobalStyle />
        <Toast />
        <ScrollToTop>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path={Pages.DESKTOP_ONLY} element={<DesktopOnly key={location.key} />} />
              <Route
                path={Pages.LOGIN}
                element={<RegistrationAndLogin forLogin={true} key={location.key} />}
              />
              <Route
                path={Pages.REGISTER}
                element={<RegistrationAndLogin forLogin={false} key={location.key} />}
              />
              {/* <Route path='/login' element={<RegistrationAndLogin forLogin={true} />} /> */}
              <Route path={Pages.HOME} element={<Home key={location.key} />} />
              <Route path={Pages.LEGAL} element={<LegalTerms key={location.key} />} />
              <Route path={Pages.PRIVACY_POLICY} element={<PrivacyPolicy key={location.key} />} />
              <Route path={Pages.PRICING} element={<YellowPlusPlans key={location.key} />} />
              <Route
                path={Pages.YELLOW_PLUS_BASIC}
                element={<YellowPlusPlans key={location.key} />}
              />
              <Route path={Pages.YELLOW_PLUS} element={<YellowPlus key={location.key} />} />

              {/* BEGIN: Moved out of private because of routing cases */}
              <Route path={Pages.MY_CART} element={<YessPlan key={location.key} />} />
              {/* <Route path={Pages.QUICK_CART} element={<QuickCart key={location.key} />} /> */}
              <Route
                path={Pages.MEMBER_DETAILS}
                element={<SignupAndConfirm key={location.key} />}
              />
              {/* END */}

              <Route path={Pages.CUSTOM_AUTH} element={<CustomAuth key={location.key} />} />
              <Route path={Pages.PAYMENT} element={<NewPayments />} />

              <Route element={<PrivateRoute />}>
                <Route path={Pages.DASHBOARD} element={<Dashboard key={location.key} />} />
                <Route
                  path={Pages.VENDOR_DASHBOARD}
                  element={<VendorDashboard key={location.key} />}
                />
                <Route path={Pages.MY_ACCOUNT} element={<Account key={location.key} />} />
                <Route path={Pages.EDIT_PROFILE} element={<EditProfile key={location.key} />} />
                <Route path={Pages.DELETE_ACCOUNT} element={<DeleteAccount />} />
                <Route
                  path={Pages.CONGRATULATIONS}
                  element={<CongratulationsPlus key={location.key} />}
                />
                {/* <Route path={Pages.PAYMENT_HISTORY} element={<PaymentsHistory />} /> */}
                <Route path={Pages.CHECKOUT} element={<PaymentSummary key={location.key} />} />
                <Route path={Pages.ONBOARDING} element={<OnBoarding key={location.key} />} />
                <Route path={Pages.WILL_EDITOR} element={<WillEditor key={location.key} />} />
                <Route path={Pages.KYC} element={<KYCVerification key={location.key} />} />
                {/* <Route path={Pages.PAYMENT} element={<PaymentsHome key={location.key} />} /> */}
                <Route path={Pages.MY_WILL} element={<MyWill key={location.key} />} />
                <Route path={Pages.MY_WILL_SIGNING_VIDEO} element={<MyWill key={location.key} />} />
                <Route
                  path={Pages.MY_WILL_UPDATE_LOCATION}
                  element={<MyWill key={location.key} />}
                />
                <Route
                  path={Pages.MY_WILL_SHIPPING_AND_COURIER}
                  element={<MyWill key={location.key} />}
                />
                {/*<Route path=Pages.MY_ACCOUNT element={<Account />} />*/}
                {/* <Route path='/preview-will' element={<PreviewWill />} /> */}
                {/* <Route path='/resources' element={<Resources />} /> */}
                {/* <Route path='/generated-will' element={<PostWillHome />} /> */}
                <Route path='/shipping-flow' element={<ShippingFlow key={location.key} />} />
              </Route>
            </Routes>
          </Suspense>
        </ScrollToTop>
      </div>
    </ThemeProvider>
  );
}

export default App;
