import styled from 'styled-components';
import arrowLeft from 'assets/svg/arrowLeft.svg';

const GoBackWrapper = styled.div`
  cursor: pointer;
  margin-top: 33px;
  margin-left: 50px;
  display: flex;
  align-items: center;

  .icon {
    height: 14px;
    object-fit: cover;
    margin-right: 10px;
  }

  .backText {
    font-size: 18px;
    //margin-left: 15px;
  }
`;

const BackToSectionSummary = ({
  text,
  action,
  noMargin,
}: {
  text: string;
  action: Function;
  noMargin?: boolean;
}) => {
  return (
    <GoBackWrapper
      id='backToSectionSummary'
      className={`backActionHolder ${noMargin ? 'noMargin' : ''}`}
      onClick={() => action()}
      style={{ ...(noMargin ? { margin: 0 } : {}) }}
    >
      <img src={arrowLeft} alt='Arrow Icon' className='icon' />
      <span className='backText'>{text}</span>
    </GoBackWrapper>
  );
};

export default BackToSectionSummary;
