export const createFreshdeskTicket = async ({
    ticketBody,
  }: {
    ticketBody: { email: string; name: string; message: string };
  }) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Basic QVBJX0tFWTpTQlRGdHBBQlZ3M2gzS1Nxd0pBcw==");

    var raw = JSON.stringify({
        "description": ticketBody.message,
        "subject": ticketBody.name,
        "email": ticketBody.email
    });

    var requestOptions: any = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch("https://domain.freshdesk.com/api/v2/tickets", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

    return true;
  };
  