import { Outlet, useNavigate } from 'react-router-dom';
import { selectUserStatus } from 'store/user/selectors';
import { useAppSelector } from 'store/hooks';
import { useEffect, useState } from 'react';
import { UserStatus } from 'store/user/slice';
import { Pages } from 'routes';
import Loading from './Loading';

export const PrivateRoute = () => {
  const userStatus = useAppSelector(selectUserStatus);
  const [showPage, setShowPage] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userStatus.includes(UserStatus.LOGGED_OUT)) navigate(Pages.LOGIN, { replace: true });
    if (userStatus.includes(UserStatus.LOGGED_IN)) setShowPage(true);
  }, [userStatus])

  if (showPage) return <Outlet />
  else return <Loading />
};
