import ReactGA from 'react-ga';

export enum EVENT_CATEGORIES {
  HOME_PAGE = 'HOME PAGE',
  ABOUT_US = 'ABOUT US',
  MY_WILL = 'MY WILL',
  PREVIEW_WILL = 'PREVIEW WILL',
  GENERATE_WILL = 'GENERATE WILL',
  FAQ = 'FAQ',
  BLOG = 'BLOG',
  CONTACT_US = 'Contact Us',
  SIGN_UP = 'SIGN UP',
}

export const getGATrackingID = () => import.meta.env.VITE_GA_TRACKING_ID as string;

//  category: Typically the object that was interacted with (e.g. 'Home')
//  action: The type of interaction (e.g. 'click')
//  label?: Useful for categorizing events (e.g. 'Fall Campaign')
const useAnalyticsEventTracker = ({ category }: { category: EVENT_CATEGORIES }) => {
  const eventTracker = ({ action, label }: { action: string; label?: string }) => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};

export default useAnalyticsEventTracker;
