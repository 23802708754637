import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { initialState } from './slice';
import { RequestLoader } from '../../utils/types';

const authSlice = (state: RootState) => state.user || initialState;

export const selectAuthUser = createSelector([authSlice], (state) => state.authUser);
export const selectAuthLoading = createSelector([authSlice], (state) => state.authLoading);
export const isprofileLoaded = createSelector([authSlice], (state) => state.isUserProfileLoaded);
export const selectLoggedOutConfig = createSelector([authSlice], (state) => state.loggedOutConfig);

export const selectIsLoading = createSelector(
  [authSlice],
  (state): boolean =>
    !state.requestStatus ||
    state.requestStatus === RequestLoader.NOT_STARTED ||
    (state.requestStatus === RequestLoader.LOADING && !!isprofileLoaded),
);

export const selectUnloggedInUserSessionData = createSelector(
  [authSlice],
  (state) => state.unloggedInUserSessionData,
);

export const selectYellowPlusPlansForAgeLocationConfig = createSelector(
  [authSlice],
  (state) => state.yellowPlusConfig,
);
export const selectYellowPlusCartData = createSelector(
  [authSlice],
  (state) => state.yellowCartData,
);
export const selectYellowMembershipConfig = createSelector(
  [authSlice],
  (state) => state.yellowMembershipConfig,
);
export const selectYellowPaymentSummaryData = createSelector(
  [authSlice],
  (state) => state.yellowPaymentSummaryData,
);
export const selectCurrentUserPlans = createSelector(
  [authSlice],
  (state) => state.currentUserPlansResponse,
);

export const selectYellowPlusDefaultPlansConfig = createSelector(
  [authSlice],
  (state) => state?.config?.configs?.plans,
);

export const selectPaymentHistory = createSelector(
  [authSlice],
  (state) => state.paymentHistoryResponse,
);

export const selectUserProfile = createSelector(
  [authSlice],
   (state) => state.userProfile
);

export const selectCreatedAt = createSelector(
  [authSlice],
  (state) => state.createdAt
);

export const selectUserContacts = createSelector(
  [authSlice],
  (state) => state.userContacts
);

export const selectContent = createSelector(
  [authSlice],
  (state) => state.content
);

export const selectUserAccessRoles = createSelector(
  [authSlice],
  (state) => state.accessRole
);

export const selectUserAssets = createSelector(
  [authSlice],
  (state) => state.userAssets
);

export const selectUserLiabilities = createSelector(
  [authSlice],
  (state) => state.userLiabilities
);

export const selectActiveWill = createSelector(
  [authSlice],
  (state) => state.will
);

export const selectUserRemainingProfileFields = createSelector(
  [authSlice],
  (state) => state.remainingProfileFields,
);

export const selectIsOnboardingComplete = createSelector(
  authSlice,
  (state) => state.remainingProfileFields.length === 0,
);

export const selectIsGuardianShow = createSelector(
  [authSlice],
  (state) => state.showGuardianSection,
);

export const selectUserStatus = createSelector(
  [authSlice],
  (state) => state.userStatus
);

export const selectConfig = createSelector(
  authSlice,
  (state) => state.config
);

export const selectSendWillToRequest = createSelector(
  authSlice,
  (state) => state?.willRecipientMailSlice?.sendWill?.request,
);

export const selectSendWillToResponse = createSelector(
  authSlice,
  (state) => state?.willRecipientMailSlice?.sendWill?.response,
);

export const selectSendWillPreviousRecipients = createSelector(
  authSlice,
  (state) => state?.willRecipientMailSlice?.previousWillRecipients,
);

export const selectWillRecipients = createSelector(
  authSlice,
  (state) => state?.willRecipients,
);
