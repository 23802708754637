import { Grid } from '@mui/material';
import styled from 'styled-components';
import closeIcon from 'assets/svg/close.svg';
import { useState } from 'react';
import BackToSectionSummary from 'Pages/MyWill/BackToSectionSummary';
import ContactActionCards from './ActionCards';
import EmailUs from './EmailUs';
import useAnalyticsEventTracker, { EVENT_CATEGORIES } from 'common/GoogleAnalytics';

const ContactUsWrapper = styled(Grid)`
  padding: 30px;
`;

const ContactActionsWrapper = styled(Grid)``;

export enum ContactUsActions {
  SHOW_ALL_ACTIONS = 'SHOW_ALL_ACTIONS',
  CHAT = 'CHAT NOW',
  EMAIL = 'EMAIL US',
  TALK_TO_EXPERT = 'TALK TO EXPERT',
}

const ContactUsHome = ({ closeIconAction }: { closeIconAction: Function }) => {
  const gaEventTracker = useAnalyticsEventTracker({ category: EVENT_CATEGORIES.CONTACT_US });
  const [currentAction, setCurrentAction] = useState(ContactUsActions.SHOW_ALL_ACTIONS);

  const getEventAction = (action: ContactUsActions) => {
    let eventAction = '';
    if (action === ContactUsActions.CHAT) eventAction = 'Chat Now';
    if (action === ContactUsActions.EMAIL) eventAction = 'Email Us';
    if (action === ContactUsActions.TALK_TO_EXPERT) eventAction = 'Talk to Expert';
    return `Clicked on ${eventAction}`;
  };

  return (
    <ContactUsWrapper>
      <Grid
        item
        lg={12}
        display={'flex'}
        justifyContent='space-between'
        alignItems={'center'}
        className='head'
      >
        {currentAction === ContactUsActions.SHOW_ALL_ACTIONS ? (
          <h4 className='heading6'>Contact Us</h4>
        ) : (
          // Padding to stop the flickering.
          <div style={{ paddingTop: '8px' }}>
            <BackToSectionSummary
              text='Contact Us'
              action={() => setCurrentAction(ContactUsActions.SHOW_ALL_ACTIONS)}
            />
          </div>
        )}

        <img
          src={closeIcon}
          alt=''
          className='cursor-pointer close_icon'
          onClick={() => closeIconAction()}
          style={{
            height: '18px',
            objectFit: 'contain',
          }}
        />
      </Grid>
      <ContactActionsWrapper item lg={12} sx={{ marginTop: '40px' }}>
        {currentAction === ContactUsActions.SHOW_ALL_ACTIONS && (
          <ContactActionCards
            setCurrentAction={(action: ContactUsActions) => {
              gaEventTracker({
                action: getEventAction(action),
                label: `Contact Us Modal`,
              });
              setCurrentAction(action);
            }}
          />
        )}
        {currentAction === ContactUsActions.CHAT && (
          <>
            <p>Please click on the chat box on the bottom right to connect with our care team ↘️</p>
          </>
        )}
        {currentAction === ContactUsActions.EMAIL && <EmailUs />}
        {currentAction === ContactUsActions.TALK_TO_EXPERT && (
          <>
            <p>Coming Soon.</p>
          </>
        )}
      </ContactActionsWrapper>
    </ContactUsWrapper>
  );
};

export default ContactUsHome;
