import { getAuth, signInWithCustomToken } from "firebase/auth"
import { useEffect, useMemo } from "react"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Pages } from 'routes';

const CustomAuth = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const customToken = useMemo(() => {
    return searchParams.get("customToken");
  }, [searchParams]);

  const redirectTo = useMemo(() => {
    return searchParams.get("redirectTo");
  }, [searchParams]);

  useEffect(() => {
    if (customToken === null || customToken === "") return;

    const auth = getAuth();
    signInWithCustomToken(auth, customToken)
      .then(() => navigate(redirectTo || Pages.VENDOR_DASHBOARD, { replace: true }))
      .catch((err) => console.log("AuthErr", err));
  }, [customToken, redirectTo]);

  if (customToken === null || customToken === "") {
    return <div><h1>Please include the user's custom token in the query param "customToken"</h1></div>
  }

  return <div></div>
}

export default CustomAuth;
