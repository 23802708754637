import { Grid } from '@mui/material';
import CustomButton from 'common/Reusables/Button';
import InputField from 'common/Reusables/Inputs';
import CustomTextArea from 'common/Reusables/Inputs/CustomTextArea';
import { useState } from 'react';
import { SubmitHandler, useForm, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { ContactUsInputs, FormRegisterInputs, HTMLInputTypes } from 'utils/types';
import success from 'assets/images/success.png';
import { regex } from 'utils';
import { createFreshdeskTicket } from 'utils/freshdesk';

const EmailUsWrapper = styled(Grid)`
  .successIcon {
    height: 50px;
    width: 50px;
    object-fit: contain;
    margin-right: 20px;
  }

  .emailFormHolder {
    margin-top: 25px;
    height: 75vh;

    form {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

const EmailUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactUsInputs>();

  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSubmit: SubmitHandler<ContactUsInputs> = async (data, event) => {
    setLoading(true);
    const status = await createFreshdeskTicket({
      ticketBody: {
        email: data.email,
        name: data.name,
        message: data.message,
      },
    });
    if (status) {
      setTimeout(() => {
        setLoading(false);
        setFormSubmitted(true);
      }, 2000);
    }
  };

  return (
    <EmailUsWrapper>
      {formSubmitted ? (
        <Grid display={'flex'} alignItems='center'>
          <img src={success} alt='' className='successIcon' />
          <span>Your message has been sent successfully!</span>
        </Grid>
      ) : (
        <>
          <p>Enter details below</p>
          <Grid item lg={12} className='emailFormHolder'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <InputField
                  type={HTMLInputTypes.TEXT}
                  register={register as UseFormRegister<FormRegisterInputs>}
                  errors={errors}
                  errorMessage='This field is required'
                  fieldToRegister='name'
                  placeHolder='Name'
                  required={true}
                />
                <br />
                <InputField
                  type={HTMLInputTypes.EMAIL}
                  register={register as UseFormRegister<FormRegisterInputs>}
                  errors={errors}
                  errorMessage='Invalid email address'
                  fieldToRegister='email'
                  placeHolder='Email'
                  required={true}
                  pattern={regex.email}
                />
                <br />
                <CustomTextArea
                  register={register as UseFormRegister<FormRegisterInputs>}
                  fieldToRegister='message'
                  placeHolder='Message'
                  required={true}
                  errors={errors}
                  errorMessage='This is a required field'
                />
              </div>

              <br />

              <CustomButton text={loading ? 'Please Wait...' : 'Submit'} />
            </form>
          </Grid>
        </>
      )}
    </EmailUsWrapper>
  );
};

export default EmailUs;
