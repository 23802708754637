import { useNavigate } from 'react-router-dom';
import { ReactEventHandler } from 'react';
import img_arrowright from 'assets/svg/header_back_left.svg';
interface BackButtonProps {
  onClick?: ReactEventHandler;
}
export default ({ onClick }: BackButtonProps) => {
  const navigate = useNavigate();
  return (
    <img
      src={img_arrowright}
      className={'h-16px mr-8px w-auto object-fit-contain'}
      onClick={onClick || (() => navigate(-1))}
    />
  );
};
