const theme = {
  // Font Size
  heading1: '52px',
  heading2: '40px',
  heading3: '36px',
  heading4: '32px',
  heading5: '28px',
  heading6: '24px',
  text: '24px',
  subText: '20px',
  normal: '16px',
  xs_text: '14px',

  // Font Weight
  fontWeight600: '600',

  // Colors
  primaryColor: '#1D2939',
  secondaryColor: '#F9EED2',
  tertiaryColor: '#00A388',
  white: '#FFFFFF',
  light_beige: '#FFF8DD',
  dark_beige: '#FFD662',
  light_gray: '#E6E5E5',
  pricingHeadingColor: '#101828',
};
export default theme;
