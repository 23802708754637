import { Text } from '../Text';
import { Img } from '../Img';
import img_user from 'assets/svg/img_user.svg';
import deleteAccount from 'assets/svg/deleteAccount.svg';
import img_icon from 'assets/svg/img_icon.svg';
// import img_trash from 'assets/svg/img_trash.svg';
import img_willhistory from 'assets/svg/img_willhistory.svg';
import img_home from 'assets/svg/img_home.svg';
import img_checkmark from 'assets/svg/img_checkmark.svg';
import img_search from 'assets/svg/img_search.svg';
import img_search_gray_900 from 'assets/svg/img_search_gray_900.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import img_arrowdown from '../../assets/svg/img_arrowdown.svg';
import img_close from '../../assets/svg/img_close.svg';
import Header from '../Header';
import { useAppSelector, useTypedDispatch } from '../../store/hooks';
import {
  selectCurrentUserPlans,
  selectYellowPlusDefaultPlansConfig,
} from '../../store/user/selectors';
import { useEffect } from 'react';
import { fetchCurrentUserPlans } from '../../store/user/slice';
import { Pages } from 'routes';

interface Props {
  currentLink?: string;
  closeIconAction?: () => unknown;
}

const items = [
  {
    icon: img_user,
    label: 'Profile',
    navigateTo: Pages.EDIT_PROFILE,
  },
  {
    type: 'membership',
  },
  // {
  //   icon: img_trash,
  //   label: 'Payment History',
  //   navigateTo: Pages.PAYMENT_HISTORY,
  // },
  {
    icon: img_willhistory,
    label: 'Will History',
    comingSoon: true,
  },
  {
    icon: img_home,
    label: 'My Addresses',
    comingSoon: true,
  },
  {
    icon: img_search,
    label: 'Invite',
    comingSoon: true,
  },
  {
    icon: img_search_gray_900,
    label: 'KYC Details',
    comingSoon: true,
  },
  {
    icon: img_checkmark,
    label: 'My Roles',
    comingSoon: true,
  },
  {
    icon: deleteAccount,
    label: 'Delete Account',
    navigateTo: Pages.DELETE_ACCOUNT,
  },
];

export default ({ closeIconAction, currentLink }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUserPlans = useAppSelector(selectCurrentUserPlans);
  const yellowPlansConfig = useAppSelector(selectYellowPlusDefaultPlansConfig);
  useEffect(() => {
    dispatch(fetchCurrentUserPlans());
  }, []);
  const oldPlans = [
    {
      'id': 'elite'
    },
    {
      'id': 'select'
    }
  ];
  const currentPlan = currentUserPlans?.data?.fetchPlans?.[0];
  const endDateStr = currentPlan?.expireAt;
  const endDate = endDateStr ? new Date(Number(endDateStr)) : new Date();
  const today = new Date();
  const expired = endDate < today;
  const currentPlanInConfig = yellowPlansConfig?.plans?.find(
    (plan) => plan?.id === currentPlan?.planId,
  ) || oldPlans.find((plan) => plan?.id === currentPlan?.planId);

  const cartPlanDetails: any = {
    'starter': {
      'title': 'Starter Will',
      'subText': 'Create your Will with yellow'
    },
    'pro': {
      'title': 'Pro Will',
      'subText': 'Create your Will with yellow'
    },
    'yess': {
      'title': 'Yellow SMART Remote Support',
      'subText': 'Online guidance for the Executor of your Will'
    },
    'smart': {
      'title': 'Yellow Smart',
      'subText': 'Asset transfer support for your loved ones'
    },
    'elite': {
      'title': 'Elite Will',
      'subText': 'Create your Will with yellow'
    },
    'select': {
      'title': 'Select Will',
      'subText': 'Create your Will with yellow'
    }
  }

  return (
    <div
      className={`background_white flex flex-grow flex-col gap-24px items-start justify-start ${
        lessThanSmall ? 'w-100 border-box hide_scrollbars pl-24px py-24px' : 'pl-56px pt-60px '
      } `}
    >
      {lessThanSmall ? (
        <Header
          title={'My Account'}
          titleBold={true}
          hideAvatar={true}
          backButton={true}
          backButtonOnClick={closeIconAction}
          backgroundColorClass={'background-transparent'}
        />
      ) : (
        <div
          className={`flex flex-row items center justify-between ${lessThanSmall ? 'pr-24px' : ''}`}
        >
          <Text className='text-gray_900 text-left w-auto font-merriweather' variant={'h6'}>
            My Account
          </Text>

          {lessThanSmall ? (
            <Img src={img_close} className='h-24px w-24px' alt='Icon' onClick={closeIconAction} />
          ) : null}
        </div>
      )}
      <div className='flex flex-col border-separate border-spacing-0-75rem border-left-none '>
        {items.map((item) => {
          // switching through this
          switch (item.type) {
            case 'membership':
              return (
                <div
                  className={`${
                    lessThanSmall ? '' : 'w-100'
                  } flex flex-row items-center mb-10px justify-between`}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={`${
                      lessThanSmall ? '' : 'w-100'
                    } cursor-pointer unselectable flex flex-row ${
                      lessThanSmall ? 'min-h-50px' : 'min-h-70px'
                    } ${
                      lessThanSmall ? 'gap-8px' : 'gap-8px'
                    } flex flex-row items-start justify-start ${
                      !lessThanSmall &&
                      ((currentLink && currentLink === Pages.MY_ACCOUNT) ||
                        location.pathname === Pages.MY_ACCOUNT)
                        ? 'my-account-side-panel-select-tab-border'
                        : ''
                    }`}
                    onClick={() => navigate(Pages.MY_ACCOUNT)}
                  >
                    <Img src={img_icon} className='h-24px w-24px' alt='Icon' />
                    <div className='flex flex-col gap-8px items-start justify-start'>
                      <Text
                        className='font-normal not-italic text-black_900 text-left w-[auto]'
                        as='h7'
                        variant='h7'
                      >
                        Plans & Add-Ons
                      </Text>
                      {currentPlan ? (
                        <div className='flex flex-row gap-8px'>
                          <div className={expired ? 'red-dot' : 'green-dot'} />
                          <Text className='font-semibold text-gray_900 text-left' variant='body1'>
                            {/* {currentPlanInConfig ? `${currentPlanInConfig.id} Plan` : ''} */}
                            {currentPlanInConfig?.id !== undefined ? cartPlanDetails[currentPlanInConfig.id].title : '...'}
                          </Text>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                  {lessThanSmall ? (
                    <img src={img_arrowdown} className='-rotate-90 h-24px w-24px px-8px' />
                  ) : null}
                </div>
              );
            default:
              return (
                <div
                  className='w-100 flex flex-row items-center mb-10px justify-between'
                  style={{ padding: '8px 0px -5px 8px' }}
                >
                  <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    className={`
                    ${lessThanSmall ? '' : 'w-100'}
                    ${item.comingSoon ? 'cursor-not-allowed' : 'cursor-pointer'} unselectable ${
                      lessThanSmall ? 'min-h-40px' : 'min-h-70px'
                    } ${lessThanSmall ? 'gap-8px' : 'gap-8px'}  ${
                      !lessThanSmall &&
                      ((currentLink && currentLink === item.navigateTo) ||
                        location.pathname === item.navigateTo)
                        ? 'my-account-side-panel-select-tab-border'
                        : ''
                    }`}
                    onClick={() => item.navigateTo && navigate(item.navigateTo)}
                  >
                    <div className='gap-8px flex flex-row items-center justify-start'>
                      <Img src={item.icon} className='h-24px w-24px' alt='home' />
                      <Text
                        className='font-normal not-italic text-black_900 text-left w-[auto]'
                        as='h7'
                        variant='h7'
                      >
                        {item.label}
                        {item.comingSoon && (
                          <>
                            {' '}
                            <Text
                              className='ml-[6px] mr-[34px] not-italic text-gray_900_70 text-left w-[auto]'
                              variant='body2'
                            >
                              (COMING SOON)
                            </Text>
                          </>
                        )}
                      </Text>
                    </div>
                  </div>
                  {lessThanSmall ? (
                    <img src={img_arrowdown} className='-rotate-90 h-24px w-24px px-8px' />
                  ) : null}
                </div>
              );
          }
          return null;
        })}
      </div>
    </div>
  );
};
