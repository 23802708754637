import { Avatar, Grid } from '@mui/material';
import BackButton from 'common/BackButton';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
// import yellowLogo from 'assets/images/yellow_logo.png';
import centricityLogo from 'assets/images/centricity-logo.png';
import cross from 'assets/svg/cross.svg';
import CustomButton from 'common/Reusables/Button';
import { selectAuthUser, selectUserProfile /*, selectUserStatus*/ } from 'store/user/selectors';
//import { UserStatus } from 'store/user/slice';
import { useAppSelector } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import { ReactEventHandler, ReactNode, useEffect, useState } from 'react';
import MuiDrawer from 'common/Reusables/Drawer';
import Menu from '../../Pages/Home/Menu';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { breakpoints } from 'styles/Breakpoints';
// import notification from 'assets/svg/img_notification.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import yellowPlusProfileIcon from 'assets/svg/yellowPlusProfileIcon.svg';
import { Pages } from 'routes';
import { Mixpanel } from 'utils/mixpanel';
import { useVendor } from 'common/useVendor';
// import { selectAuthStatus } from 'store/auth/slice';
// import { AuthStatus } from 'store/auth/types';

const NavbarWrapper = styled.div`
  padding: 10px 0px 10px;
  width: 100%;
  align-items: center;
  border-bottom: inset;
  .howItWorks {
    width: 48%;
    height: 50px;
    border: 2px solid ${(props) => props.theme.primaryColor};
    box-sizing: border-box;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      color: ${(props) => props.theme.primaryColor};
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 12px;

      .playBtn {
        height: 20px;
        width: 20px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
  .navItemsHolder {
    padding: 0 5rem;
    .logo {
      height: 50px;
      max-height: 50px;
    }
    .navTitlePad {
      display: flex;
      align-items: flex-start;
    }
    .navTitle {
      display: none;
    }
    .nav-item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      gap: 20px;
      /* min-width: 100px; */
      flex-wrap: nowrap;
      // margin-right: 30px;
      max-width: fit-content;
      white-space: nowrap;
    }
    .item-menu {
      padding: 0;
    }
    .service-link {
      text-decoration: none;
      color: black;
    }
    /* .service-link:hover {
      color: white;
    } */
    .item-list {
      list-style: none;
      /* padding: 25px; */
      padding-left: 16px;
      padding-top: 16px;
      padding-bottom: 10px;
      font-family: 'General Sans';
      box-sizing: border-box;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
    }
    .item-list:hover {
      background-color: #1d2939;
      color: #fff;
    }
    .item-list:hover .service-link {
      color: #fff;
    }
    .item-list:hover .item-subtext {
      font-weight: 900 !important;
    }
    .item-subtext {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #ffd662;
    }
    .down-arrow-img {
      margin-left: 5px;
    }
    .dropdown-container {
      position: absolute;
      top: 24px;
      left: -0.5px;
      z-index: 999;
    }
    .dropdown {
      min-height: 150px;
      width: 244px;
      z-index: 1000 !important;
      background-color: white;
      box-shadow: 0px 0px 8px rgba(97, 94, 87, 0.14);
      border-radius: 4px;
    }

    .navItem {
      padding: 3px;
      color: ${(props) => props.theme.primaryColor};
    }
    .navActive {
      text-decoration: underline;
      text-decoration-thickness: 3px;
      text-underline-offset: 5px;
    }
    .line {
      display: none;
    }
    .service-container:hover .line {
      display: block;
      margin-top: 3px;
      height: 3px;
      background-color: #1d2939;
      border: none;
    }

    .service-container-spacing {
      height: 26px;
      padding: 3px;
      position: relative;
    }
  }
  @media screen and (max-width: ${breakpoints.md}) {
    .navItemsHolder {
      padding: 0 40px;
      .navTitle {
        display: none;
      }
    }
  }
  @media screen and (max-width: ${breakpoints.sm}) {
    // padding: 40px 0px 20px;
    // padding: 4px 0px 0px;
    padding: 0;
    border-bottom: none;
    // background-color: #f8f8f8;
    .navItemsHolder {
      display: flex;
      padding: 32px 30px 24px 24px;
      // padding: 1rem 0px 0px 0px; Fix Will Editor UI Issues??
      // width: 100%;
      // .nav-item {
      //   margin-right: 1rem;
      // }
      .navTitlePad {
        width: 70%;
        @media screen and (max-width: ${breakpoints.sm}) {
          flex-direction: column;
          width: auto;
          max-width: 80%;
          // padding-left: 1rem;
        }
        justify-content: center;
        display: flex;
      }
      .navTitle {
        display: flex;
      }
    }

    .logo {
      // display: none;
    }

    .account-name {
      display: none;
    }

    .account-icon-padding {
      padding: 5px;
    }

    .service-container {
      display: none;
    }

    .navItem {
      display: none;
      // margin-right: 0px;
    }

    .notification-img {
      display: none;
    }
  }
`;

enum ACTIVE_STATES {
  HOME = 'HOME',
  PRODUCTS = 'PRODUCTS',
  ABOUT_US = 'ABOUT_US',
  FAQ = 'FAQ',
  BLOG = 'BLOG',
  MY_WILL = 'MY_WILL',
  SERVICES = 'SERVICES',
}

interface ChooseTitle {
  title: string;
  titleBold?: boolean;
  backButton: boolean;
  backButtonOnClick?: Function;
  backgroundColorClass?: string;
  hideAvatar?: boolean;
  noPadding?: boolean;
  centerItems?: boolean;
  renderRightHandSide?: ReactNode;
  hideBackNavigation?: boolean;
  hideLogo?: boolean;
}

const shouldHideMyWillAndWillEditorFor = (pathname: string) => {
  switch (pathname) {
    case Pages.MY_CART:
    case Pages.PAYMENT:
      return true;
  }
  return false;
};

const shouldHideYellowPlusFor = (pathname: string) => {
  switch (pathname) {
    case Pages.YELLOW_PLUS:
    case Pages.PRICING:
    case Pages.YELLOW_PLUS_BASIC:
    case Pages.MY_CART:
    case Pages.PAYMENT:
      return true;
  }
  return false;
};

const Navbar: React.FC<ChooseTitle> = ({
  title,
  titleBold,
  backButton,
  backgroundColorClass = 'background-lightgray',
  backButtonOnClick,
  hideAvatar,
  noPadding,
  centerItems,
  renderRightHandSide,
  hideBackNavigation = false,
  hideLogo = false,
}: {
  title?: string;
  titleBold?: boolean;
  backButton?: boolean;
  backgroundColorClass?: string;
  backButtonOnClick?: Function;
  hideAvatar?: boolean;
  noPadding?: boolean;
  centerItems?: boolean;
  renderRightHandSide?: ReactNode;
  hideBackNavigation?: boolean;
  hideLogo?: boolean;
}) => {
  const authUser = useAppSelector(selectAuthUser);
  // const authStatus = useAppSelector(selectAuthStatus);
  const userProfile = useAppSelector(selectUserProfile);
  //const userStatus = useAppSelector(selectUserStatus);
  const navigate = useNavigate();
  const { logo } = useVendor();
  const location = useLocation();
  const [services, setServices] = useState(false);
  const [currentActiveState, setActiveState] = useState<ACTIVE_STATES>(ACTIVE_STATES.HOME);
  const theme = useTheme();
  const [testatorName] = useState(localStorage.getItem('testatorName') !== null ? localStorage.getItem('testatorName') : (userProfile ? userProfile?.fullName : null));
  const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const shouldHideMyWillAndWillEditorForCurrentPath = shouldHideMyWillAndWillEditorFor(
    location.pathname,
  );
  const shouldHideYellowPlusForCurrentPath = shouldHideYellowPlusFor(location.pathname);

  const [showDrawer, setShowDrawer] = useState(false);
  const [shouldShowCentricityBrand, setShouldShowCentricityBrand] = useState(false);

  const closeDropdown = () => {
    setServices(false);
  };
  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  // useEffect(() => {
  //   if(userProfile && userProfile?.fullName)
  //     setTestatorName(userProfile.fullName.split(' ')[0]);

  // },[userProfile])

  useEffect(() => {
    if (window.location?.search === '') return;

    const searchParameters = window.location?.search
      .split('/')[0]
      .replace('?', '')
      .split('&')
      .reduce((acc, curr) => {
        const [k, value] = curr.split('=');
        return {
          ...acc,
          [k]: value,
        };
      }, {});

    Mixpanel.track('UTM Link', searchParameters);

    // @ts-ignore
    if (searchParameters['utm_source'] === 'Centricity') {
      setShouldShowCentricityBrand(true);
    } else {
      setShouldShowCentricityBrand(false);
    }
  }, [window.location?.search]);

  useEffect(() => {
    if (location.pathname === '/my-will') setActiveState(ACTIVE_STATES.MY_WILL);
    if (location.pathname === '/generated-will') setActiveState(ACTIVE_STATES.MY_WILL);
    if (location.pathname === '/faq') setActiveState(ACTIVE_STATES.FAQ);
    if (location.pathname === '/blog') setActiveState(ACTIVE_STATES.BLOG);
    if (location.pathname === '/about-us') setActiveState(ACTIVE_STATES.ABOUT_US);
    if (location.pathname === '/products') setActiveState(ACTIVE_STATES.PRODUCTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleServices = () => {
    if (services === false) {
      setServices(true);
    } else {
      setServices(false);
    }
  };

  return (
    <NavbarWrapper
      /*
      // @ts-ignore*/
      lessThanSmall={lessThanSmall}
      className={backgroundColorClass}
    >
      <Grid
        container
        lg={12}
        justifyContent='space-between'
        {...(centerItems ? { alignItems: 'center' } : {})}
        flexWrap={'wrap'}
        className={`navItemsHolder ${lessThanSmall && !noPadding ? 'px-24px' : ''}`}
      >
        <Grid item lg={5} className='navTitlePad'>
          {!hideLogo && (
            <img
              // className={authStatus === AuthStatus.LOGGED_IN ? `logo` : `logo cursor-pointer`}
              className={authUser ? `logo` : `logo cursor-pointer`}
              src={logo}
              alt='Yellow Logo'
              onClick={() => {
                // if (authStatus !== AuthStatus.LOGGED_IN) {
                if (!authUser) {
                  setTimeout(() => (window.location.href = 'https://getyellow.in/'), 200);
                }
              }}
            />
          )}
          {shouldShowCentricityBrand && (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                style={{
                  filter: 'grayscale(1)',
                  margin: '0 25px',
                  width: '20px',
                }}
                src={cross}
                alt='cross'
              />
              <img width='120px' src={centricityLogo} alt='centricity-logo' />
            </span>
          )}
          <div
            className={`navTitle flex flex-row items-center justify-start gap-8px h-auto w-100 nowrap ${hideBackNavigation ? 'hide_in_mobile_flex' : 'show_in_mobile_flex'
              }`}
          >
            {backButton && (
              <BackButton
                {...(backButtonOnClick
                  ? { onClick: backButtonOnClick as unknown as ReactEventHandler }
                  : {})}
              />
            )}
            <h3 className={titleBold ? 'font-roboto-bold' : 'subText'}>{title}</h3>
          </div>
        </Grid>
        {/* <Grid item lg={authStatus === AuthStatus.LOGGED_IN ? 3.5 : 4} className='nav-item'> */}
        <Grid item lg={authUser ? 3.5 : 4} className='nav-item'>
          {/* <NavItem
            title='Products'
            handle=Pages.MY_ACCOUNT
            isActive={currentActiveState === ACTIVE_STATES.PRODUCTS}
          /> */}
          {/* <NavItem
            title='About Us'
            handle='/about-us'
            isActive={currentActiveState === ACTIVE_STATES.ABOUT_US}
          /> */}
          {/* <OutsideClickHandle> */}
          <Grid
            onClick={() => {
              handleServices();
            }}
            ref={ref}
            className='service-container service-container-spacing'
          >
            {/* <Grid>
              <NavItem title='Services' handle='' isActive={false} />
              <img src={downarrow} alt='down-arrow' className='down-arrow-img' />
              <hr className='line' />
            </Grid> */}
            {/* <Grid className='dropdown-container'>
              <div className='dropdown' style={{ display: services === true ? 'block' : 'none' }}>
                {' '}
                <ul className='item-menu'>
                  <Link
                    className='service-link'
                    to={authUser && authUser.id ? '/my-will' : '/login'}
                  >
                    <li className='item-list'>Yellow Will</li>
                  </Link>
                  <li className='item-list'>
                    Yellow Trusts <br />
                    <span className='item-subtext'>Coming soon </span>
                  </li>
                  <li className='item-list'>
                    Yellow Claims <br />
                    <span className='item-subtext'>Coming soon </span>
                  </li>

                </ul>
              </div>
            </Grid> */}
          </Grid>
          {/* </OutsideClickHandle> */}
          {!shouldHideYellowPlusForCurrentPath ? (
            <Grid className='navItem'>
              <NavItem
                title={'My Home'}
                handle={(userProfile?.externalVendor === undefined || userProfile.externalVendor === null) ? Pages.DASHBOARD : Pages.VENDOR_DASHBOARD}
              // isActive={currentActiveState === ACTIVE_STATES.HOME}
              />
            </Grid>
          ) : null}
          {/* {!shouldHideYellowPlusForCurrentPath ? ( */}
          {/*   <Grid className='navItem'> */}
          {/*     <NavItem */}
          {/*       title={'Pricing'} */}
          {/*       handle={Pages.YELLOW_PLUS} */}
          {/*       isActive={currentActiveState === ACTIVE_STATES.BLOG} */}
          {/*     /> */}
          {/*   </Grid> */}
          {/* ) : null} */}
          {!shouldHideMyWillAndWillEditorForCurrentPath && authUser ? (
            <Grid className='navItem'>
              <NavItem
                title={'My Will'}
                handle={Pages.MY_WILL}
                isActive={currentActiveState === ACTIVE_STATES.BLOG}
              />
            </Grid>
          ) : null}
          {/*{!shouldHideMyWillAndWillEditorForCurrentPath && authUser && authUser.id ? (
            <Grid className='navItem'>
              <NavItem
                title={'Will Editor'}
                handle={Pages.WILL_EDITOR}
                isActive={currentActiveState === ACTIVE_STATES.BLOG}
              />
            </Grid>
          ) : null}*/}
          {/*<Grid
            onClick={() => {
              handleServices();
            }}
            ref={ref}
            className='service-container service-container-spacing'
          >
            <Grid>
              <NavItem title='Resources' handle='' isActive={false} />
              <img src={downarrow} alt='down-arrow' className='down-arrow-img' />
              <hr className='line' />
            </Grid>
             <Grid className='dropdown-container'>
              <div className='dropdown' style={{ display: services === true ? 'block' : 'none' }}>
                {' '}
                <ul className='item-menu'>
                  <Link
                    className='service-link'
                    to={authUser && authUser.id ? '/my-will' : '/login'}
                  >
                    <li className='item-list'>Yellow Will</li>
                  </Link>
                  <li className='item-list'>
                    Yellow Trusts <br />
                    <span className='item-subtext'>Coming soon </span>
                  </li>
                  <li className='item-list'>
                    Yellow Claims <br />
                    <span className='item-subtext'>Coming soon </span>
                  </li>

                </ul>
              </div>
            </Grid>
          </Grid>*/}
          <NavItem
            title='Blog'
            handle='/blog'
            isActive={currentActiveState === ACTIVE_STATES.BLOG}
          />
          <NavItem title='FAQs' handle='/faq' isActive={currentActiveState === ACTIVE_STATES.FAQ} />

          <>
            {/* <div className='howItWorks cursor-pointer'>
                <a
                  className='content'
                  onClick={() => {
                    // navigate('/register');
                    window.open('https://play.google.com/store/apps/details?id=com.getyellow.app');
                  }}
                >
                  <span>Download the app</span>
                </a>
              </div> */}
            {/* <CustomButton
                text='Download the app'
                className='getStartedCta'
                onClick={() => {
                  // navigate('/register');
                  window.open('https://play.google.com/store/apps/details?id=com.getyellow.app');
                }}
                hoverColor='#1D2939'
                hoverBackgroundColor='#FFC632'
              /> */}

            {renderRightHandSide ? (
              renderRightHandSide
            ) : hideAvatar ? null : authUser ? (
              <div
                className='cursor-pointer bg-amber_400 flex flex-row gap-8px items-center justify-center pl-24px pr-4px py-4px rounded-radius24 account-icon-padding'
                onClick={() => {
                  if (userProfile) setShowDrawer(true);
                  else navigate(Pages.LOGIN);
                }}
              >
                <a
                  href='#'
                  className='cursor-pointer font-semibold text-16px text-bluegray_900 text-left w-auto account-name'
                  rel='noreferrer'
                >
                  {
                    testatorName !== null ? (
                      `Hi, ${testatorName.split(' ')[0]}`
                    ) : (
                      userProfile === null ? (
                        'Login'
                      ) : (
                        userProfile?.fullName === null ? (
                          'Hello'
                        ) : (
                          `Hi, ${userProfile.fullName.split(' ')[0]}`
                        )
                      )
                    )
                  }

                  {/* {userProfile ? `Hi, ${userProfile.fullName.split(' ')[0]}` : 'Login'} */}
                </a>
                <Avatar
                  src={yellowPlusProfileIcon || userProfile?.profilePicture}
                  sx={{ height: '40px', width: '40px', marginTop: '1px' }}
                />
              </div>
            ) : (
              <div
                className='cursor-pointer bg-amber_400 flex flex-row gap-8px items-center justify-center pl-24px pr-4px py-4px rounded-radius24 account-icon-padding'
                onClick={() => {
                  if (userProfile) setShowDrawer(true);
                  else navigate(Pages.LOGIN);
                }}
              >
                <a
                  href='#'
                  className='cursor-pointer font-semibold text-16px text-bluegray_900 text-left w-auto account-name'
                  rel='noreferrer'
                >
                  {'Login'}
                </a>
                <Avatar
                  src={yellowPlusProfileIcon || userProfile?.profilePicture}
                  sx={{ height: '40px', width: '40px', marginTop: '1px' }}
                />
              </div>
            )}

            {/* {hideAvatar ? null : (
              <div
                className='cursor-pointer bg-amber_400 flex flex-row gap-8px items-center justify-center pl-24px pr-4px py-4px rounded-radius24 account-icon-padding'
                onClick={() => setShowDrawer(true)}
              >
                <a
                  href='#'
                  className='cursor-pointer font-semibold text-16px text-bluegray_900 text-left w-auto account-name'
                  rel='noreferrer'
                >
                  Hi, Venkatesh
                </a>
                <Avatar
                  src={userProfile?.profilePicture || ''}
                  sx={{ height: '40px', width: '40px', marginTop: '1px' }}
                />
              </div>
            )} */}
            {/*<img src={notification} className='notification-img h-32px' alt='notification' />*/}
          </>
        </Grid>
      </Grid>
      <MuiDrawer
        open={showDrawer}
        hideDrawer={() => setShowDrawer(false)}
        width={'min(100%, 500px)'}
      >
        <Menu hideDrawer={() => setShowDrawer(false)} />
      </MuiDrawer>
    </NavbarWrapper>
  );
};

export default Navbar;

type NavItemProps = {
  title: string;
  handle: string;
  isButton?: boolean;
  isActive?: boolean;
};

const NavItem = ({ title, handle, isButton, isActive }: NavItemProps) => {
  const navigate = useNavigate();

  if (handle === '/blog') {
    return (
      <a
        className='navItem font-semi-bold'
        href='https://getyellow.in/resources'
        target='_blank'
        onClick={() => Mixpanel.track('Blog click', { from: 'Navbar' })}
      >
        {title}
      </a>
    );
  }

  if (handle === Pages.FAQ) {
    return (
      <a
        className='navItem font-semi-bold'
        href='https://getyellow.in/faq'
        target='_blank'
        onClick={() => Mixpanel.track('FAQ click', { from: 'Navbar' })}
      >
        {title}
      </a>
    );
  }

  return isButton ? (
    <CustomButton
      text={title}
      handle={handle}
      onClick={() => {
        Mixpanel.track(`${handle} click`, { from: `Navbar` });
        navigate(handle);
      }}
    ></CustomButton>
  ) : (
    <Link
      to={handle}
      onClick={() => {
        Mixpanel.track(`${handle} click`, { from: `Navbar` });
      }}
      className={`${isActive && 'navActive'} navItem font-semi-bold`}
    >
      {title}
    </Link>
  );
};
