import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import userReducer from 'store/user/slice';
// import authReducer from 'store/auth/slice';
import toastReducer from 'store/toast/slice';
// import profileReducer from 'store/profile/slice';
// import willReducer from 'store/will/slice';
// import yPlusReducer from 'store/yplus/slice';
// import mockSaga from './mockSaga';
// import faqsReducer from 'store/FAQs/slice';
import rootSaga from './rootSaga';
import paymentsReducer from "./payments";

// enableMapSet();

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  toast: toastReducer,
  user: userReducer,
  payments: paymentsReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;

sagaMiddleware.run(rootSaga);
