import { InputProps } from 'utils/types';
import { checkForErrors } from '../formUtils';

import styled from 'styled-components';

const TextAreaWrapper = styled.div`
  textarea {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    width: 100%;
    height: 150px;
    border-radius: 12px;
    border: 0;
    resize: none;

    padding-top: 10px;
    padding-left: 10px;
    font-family: Nunito Sans, sans-serif;
    font-size: 16px;

    :focus {
      outline: 0;
    }

    :invalid {
      border: 0;
    }

    :valid {
      border: 2px solid;
    }
  }

  .errorMessage {
    margin: 10px 0px;
    color: #ba1b1b;
  }
`;

const CustomTextArea = ({
  register,
  errors,
  errorMessage,
  fieldToRegister,
  placeHolder,
  required = false,
  pattern,
}: Omit<InputProps, 'type'>) => {
  const registerConfig = {
    required,
    ...(pattern ? { pattern } : {}), // Uses pattern only if any valid regex is passed as a prop.
  };

  return (
    <TextAreaWrapper>
      <textarea
        {...register(fieldToRegister, registerConfig)}
        placeholder={placeHolder}
        className='inputBackground'
        required={required as boolean}
      />
      {errors && Object.keys(errors).length > 0 && checkForErrors({ fieldToRegister, errors }) && (
        <p className='errorMessage'>{errorMessage}</p>
      )}
    </TextAreaWrapper>
  );
};

export default CustomTextArea;
