import Navbar from "common/Header";
import Loading from "common/Loading";
import Button from 'common/Reusables/Button';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { selectUserProfile } from "store/user/selectors";
import { Box, useMediaQuery } from "@mui/material";
import Modal from "@mui/base/Modal";
import { Mixpanel } from "utils/mixpanel";
import { Intercom } from "utils/intercom";
import { runMutation } from "utils/graphQL";
import { DELETE_ACCOUNT } from "common/queries";
import { useDispatch } from "react-redux";

enum Reason {
  FILLED_IN_WRONG_INFO,
  CREATED_WILL_NO_LONGER_NEED,
  PREFER_OFFLINE,
  APP_NOT_AS_EXPECTED,
  APP_DIFFICULT,
  LEGAL_LANGUAGE_OVERWHELMING,
  NONE
}

const reasons = [
  { id: Reason.FILLED_IN_WRONG_INFO, reason: "I filled in the wrong information while exploring the app and now I wish to restart" },
  { id: Reason.CREATED_WILL_NO_LONGER_NEED, reason: "I have created my Will and no longer need services from Yellow" },
  { id: Reason.PREFER_OFFLINE, reason: "I prefer to make my Will offline with lawyers" },
  { id: Reason.APP_NOT_AS_EXPECTED, reason: "The app did not work as expected." },
  { id: Reason.APP_DIFFICULT, reason: "I found the app to be very difficult to use" },
  { id: Reason.LEGAL_LANGUAGE_OVERWHELMING, reason: "I found the legal language overwhelming" },
  { id: Reason.NONE, reason: "None of the above reasons" },
];

const popupStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(90%, 650px)',
  boxSizing: 'border-box',
  bgcolor: 'background.paper',
  padding: '40px',
  borderRadius: '12px',
  boxShadow: '0px 4px 22px rgba(255, 198, 50, 0.16)',
  p: 4,
};


export const DeleteAccount = () => {
  const userProfile = useAppSelector(selectUserProfile);
  const currentTheme = useTheme();
  const lessThanLarge = useMediaQuery(currentTheme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedReason, setSelectedReason] = useState<Reason | undefined>();
  const [otherReason, setOtherReason] = useState<string>("");
  const [submitPressed, setSubmitPressed] = useState<boolean>(false);
  const [confirmPressed, setConfirmPressed] = useState<boolean>(false);

  useEffect(() => {
    if (userProfile) setIsLoading(false);
  }, [userProfile])

  if (isLoading) return <Loading />

  const onDeleteHandler = () => {
    setSubmitPressed(true);
  }

  const onConfirmDeleteHandler = async () => {
    setConfirmPressed(true);
    // track on mixpanel
    Mixpanel.track("Account deleted", { reason: Reason[selectedReason!] });
    Mixpanel.setUserProperties({ "Account Deletion Reason": Reason[selectedReason!], "Date of Account Deletion": new Date() });

    // track on intercom
    Intercom.track("Account deleted", { reason: Reason[selectedReason!] });
    Intercom.update({ "Account Deletion Reason": Reason[selectedReason!] });

    // await runMutation({
    //   mutation: MAILCHIMP_SEND_MAIL_TEMPLATE,
    //   variables: {
    //     key: 'input',
    //     value: {
    //       email: "kenny.jacob@getyellow.in",
    //       subject: 'Yellow Account Deleted',
    //       templateName: 'Yellow Account Delete Message',
    //       templateContent: [
    //         {
    //           name: 'FNAME',
    //           content: "Kenny",
    //         },
    //         {
    //           name: 'USERID',
    //           content: "ldkfjaslkfsajdkf8923ur983h2ufniw",
    //         },
    //         {
    //           name: 'FULLNAME',
    //           content: "Kenny John Jacob",
    //         },
    //         {
    //           name: 'REASON',
    //           content: reasons.find(r => r.id === selectedReason)!.reason,
    //         },
    //         {
    //           name: 'NOTES',
    //           content: otherReason,
    //         },
    //       ],
    //     },
    //   },
    // });

    // await runMutation({
    //   mutation: MAILCHIMP_SEND_MAIL_TEMPLATE,
    //   variables: {
    //     key: 'input',
    //     value: {
    //       email: "kenny.jacob@getyellow.in",
    //       subject: 'Yellow Account Deleted',
    //       // templateName: 'Yellow Account Delete Message',
    //       templateName: 'Yellow Account Delete Helpdesk',
    //       templateContent: [
    //         {
    //           name: 'FNAME',
    //           content: "Kenny",
    //         },
    //         {
    //           name: 'CLIENTID',
    //           content: "ldkfjaslkfsajdkf8923ur983h2ufniw",
    //         },
    //         {
    //           name: 'FULLNAME',
    //           content: "Kenny John Jacob",
    //         },
    //         {
    //           name: 'REASON',
    //           content: reasons.find(r => r.id === selectedReason)!.reason,
    //         },
    //         {
    //           name: 'NOTES',
    //           content: otherReason,
    //         },
    //       ],
    //     },
    //   },
    // });

    // call the mutation to delete the account
    await runMutation({
      mutation: DELETE_ACCOUNT,
      variables: {
        key: "input",
        value: {
          reason: reasons.find(r => r.id === selectedReason)!.reason,
          notes: otherReason
        }
      }
    });

    // trigger the action to logout
    dispatch({ type: 'LOGOUT_ATTEMPT' });
  }

  return <>
    <Navbar title="Delete Account" backButton={false} />
    <Modal style={{ background: "rgba(0, 0, 0, 0.2)", height: "100vh" }}
      open={submitPressed}
      onClose={() => setSubmitPressed(false)}>
      <Box sx={popupStyle}>
        <h1>Confirm</h1>
        <p style={{ marginTop: 32 }}>Are you sure you want to delete your account?</p>
        <Button disabled={confirmPressed} onClick={onConfirmDeleteHandler} style={{ marginTop: 32, width: "100%" }} text="Yes, delete my account" />
      </Box>

    </Modal>
    <div style={{
      backgroundColor: "#f7f3ed",
      height: "100%",
    }}>
      <div style={{ margin: lessThanLarge ? "auto 5%" : "auto 16%", padding: lessThanLarge ? 16 : 64 }}>
        <h1 className="font-merriweather">Delete Account</h1>
        <div style={{ marginTop: 16 }}>Hi <b>{userProfile?.fullName},</b></div>
        <p style={{ marginTop: 16 }}>We’re sorry to hear that you would like to delete your account.</p>

        <h2 style={{ marginTop: 32 }}>About your data</h2>
        <p style={{ marginTop: 16 }}>
          <b>At Yellow your data security is our highest priority.</b>
          Thank you for trusting us for making your Will or availing any of our services. Our commitment to you is that we’ll never use your data without your permission, or sell it to a third party.
          To know more about our security and privacy policy <a href="#">click here</a>.
        </p>
        <p style={{ marginTop: 32 }}><b>If you still wish to delete your account please note that:</b></p>
        <p style={{ marginTop: 16 }}>
          All personal details, contact data and information that you have entered to create your Will, including any Wills you have generated using Yellow will be deleted.
        </p>
        <p style={{ marginTop: 16 }}>
          If you have purchased a Yellow Plus membership plan or add ons, they will be cancelled. Since the data is encrypted, once deleted, none of this information can be retrieved.
        </p>
        <p style={{ marginTop: 16 }}>
          We recommend that you download any previously generated Wills so as not to lose these.
        </p>

        <div style={{ marginTop: 24, backgroundColor: "white", padding: 24, borderRadius: 10 }}>
          <h2>Why would you like to delete your account?</h2>
          <div style={{
            padding: 24,
            backgroundColor: "#f3f4f5",
            marginTop: 16,
            borderRadius: 10
          }}>
            <ul style={{
              margin: 0,
              padding: 0,
              listStyleType: "none",
            }}>
              {
                reasons.map(({ id, reason }) => {
                  return <li key={id} style={{ marginTop: 8, cursor: "pointer" }} onClick={() => setSelectedReason(id)}>
                    <input type="radio" checked={selectedReason === id} style={{ marginRight: 8 }} />
                    {reason}
                    {selectedReason === id &&
                      [Reason.APP_DIFFICULT, Reason.APP_NOT_AS_EXPECTED, Reason.NONE].includes(selectedReason) &&
                      <div style={{ marginTop: 16 }}>
                        <input
                          style={{
                            width: "95%",
                            backgroundColor: "#eaecee",
                            lineHeight: 3,
                            borderRadius: 8,
                            paddingLeft: 16,
                            fontSize: "medium"
                          }}
                          type="text"
                          placeholder={[Reason.APP_DIFFICULT, Reason.APP_NOT_AS_EXPECTED].includes(selectedReason) ? "Please tell us how we can do better" : "State your reason"}
                          onChange={(ev) => setOtherReason(ev.target.value)}
                          value={otherReason}
                        />
                      </div>
                    }
                  </li>
                })
              }
            </ul>
          </div>
          <Button text="Submit" style={{ marginTop: 16, width: "100%" }}
            onClick={onDeleteHandler}
            disabled={submitPressed || selectedReason === undefined || ([Reason.APP_DIFFICULT, Reason.APP_NOT_AS_EXPECTED, Reason.NONE].includes(selectedReason) && otherReason === "")} />
        </div>
      </div>
    </div>
  </>;
}
