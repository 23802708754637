import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectUserProfile } from 'store/user/selectors';
import yellowAckoLogo from 'assets/svg/yellowAcko.svg';
import yellowWelldercareLogo from 'assets/svg/yellowWelldercareIksana.svg';
import yellowLogo from 'assets/images/yellow_logo_2024.png';
import yellowHappy60PlusLogo from "assets/svg/yellowHappy60Plus.svg";
import yellowGensxtyLogo from "assets/svg/yellowGensxtyLogo.svg";
import yellowABCDLogo from "assets/svg/yellowABCDLogo.svg";

export const useVendor = () => {
  const location = useLocation();
  const [isVendor, setIsVendor] = useState<boolean>(false);
  const [isAcko, setIsAcko] = useState<boolean>(false);
  const [isWelldercare, setIsWelldercare] = useState<boolean>(false);
  const [isHappy60Plus, setIsHappy60Plus] = useState<boolean>(false);
  const [isGenSxty, setIsGenSxty] = useState<boolean>(false);
  const [isABCD, setIsABCD] = useState<boolean>(false);
  const [shouldShowLogo, setShouldShowLogo] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>(yellowLogo);
  const userProfile = useAppSelector(selectUserProfile);


  useEffect(() => {
    const externalVendor = userProfile && userProfile.externalVendor;
    if (!externalVendor) return;

    setIsVendor(true);

    switch (userProfile.externalVendor) {
      case "ACKO": {
        setIsAcko(true);
        setLogo(yellowAckoLogo);
        break;
      }
      case "WELLDERCARE": {
        setIsWelldercare(true);
        setLogo(yellowWelldercareLogo);
        break;
      }
      case "HAPPY60PLUS": {
        setIsHappy60Plus(true);
        setLogo(yellowHappy60PlusLogo);
        break;
      }
      case "GENSXTY": {
        setIsGenSxty(true);
        setLogo(yellowGensxtyLogo);
        break;
      }
      case "ABCD": {
        setIsABCD(true);
        setLogo(yellowABCDLogo);
        break;
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (!isVendor) return;

    const isWillEditorPage = location.pathname.startsWith("/will-editor");
    setShouldShowLogo(!isWillEditorPage);
  }, [location, isVendor]);

  return {
    isVendor,
    isAcko,
    isWelldercare,
    isHappy60Plus,
    isGenSxty,
    isABCD,
    shouldShowLogo,
    logo
  }
}
