import CustomButton from 'common/Reusables/Button';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { formatToInFormat } from '../../utils/NumberFormatter';
import { breakpoints } from '../../styles/Breakpoints';

// const SaveDetailsFloatingButtonWrapper = styled.div`
//   position: fixed;
//   bottom: 0;
//   right: 0;
//   z-index: 1000;

//   height: 60px;
//   width: ${document.body.offsetWidth};
//   background-color: ${theme.primaryColor};

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   button {
//     cursor: pointer;
//     width: 100%;
//     height: 100%;
//     background: none;
//     border: 0;
//     color: #ffffff;

//     :disabled {
//       opacity: 0.5;
//       cursor: not-allowed;
//     }
//   }
// `;

export const Wrapper = styled.div`
  .successMessage {
    width: 100%;
    padding: 20px;
    padding-left: 80px;
    padding-right: 80px;
    background-color: #FFE480;
    color: black;
    align-items: center;
    font-weight: bold;
    font-size: 24px;

    @media screen and (max-width: ${breakpoints.md}) {
      font-size: 16px;
      background-color: #ffffff;
      border-radius: 16px;
      box-shadow: 0px -4px 45px -8px rgba(0, 0, 0, 0.51);
      z-index: 3 !important;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      gap: 10px;
    }

  .priceButton {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #101828;
    p {
      padding-left: 15px;
      padding-right: 25px;
      font-size: 16px;
      font-weight: bold;
      @media screen and (max-width: ${breakpoints.md}) {
        padding: 0;
        font-weight: normal;
        color: rgba(16, 24, 40, 0.48);
      }
    }
    @media screen and (max-width: ${breakpoints.md}) {
      width: 100%;
      flex-direction: column !important;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;
    }
  } 

  .continueWillBtn {
    background: #1D2939;
    border-radius: 10px;
    margin-left: 20px;
  }

`;

const PayButton = ({
  //   width,
  disabled = false,
  onClick,
  totalAmount,
  text,
  button,
  hideText,
}: {
  //   width: number;
  disabled?: boolean;
  onClick?: Function;
  btnText?: string;
  totalAmount?: number;
  text?: string;
  button?: boolean;
  hideText?: boolean;
}) => {
  return (
    // <SaveDetailsFloatingButtonWrapper
    //   //   type={type}
    //   style={{ width: document.body.offsetWidth > 600 ? '50%' : '100%' }}
    // >
    //   <button type='button' disabled={disabled} onClick={() => onClick()}>
    //     Continue to Pay
    //     {/* {loading ? 'Please Wait...' : btnText ? btnText : 'Confirm & Continue'} */}
    //   </button>
    // </SaveDetailsFloatingButtonWrapper>
    <Wrapper>
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        className='successMessage'
        style={{ position: 'fixed', bottom: 0, width: '100vw' }}
        justifyContent={'space-between'}
      >
        {hideText ? null : text}
        <div className='priceButton'>
          {/* {config!!.configs.mobilePlans['Premium Will'] &&
            config!!.configs.mobilePlans['Premium Will'].basePrice > 0 ? (
            <p>₹{config!!.configs.mobilePlans['Premium Will'].basePrice}*</p>
          ) : null} */}
          {!hideText && totalAmount && totalAmount > 0 ? (
            <span style={{ marginRight: button ? '' : '330px' }}>
              ₹ {formatToInFormat(totalAmount)}
            </span>
          ) : null}
          {button && (
            <CustomButton
              style={{ width: '296px' }}
              // text={
              //   config!!.configs.mobilePlans['Premium Will'].basePrice > 0
              //     ? 'Proceed to checkout'
              //     : 'Proceed'
              // }
              text='Proceed to Payment'
              className='continueWillBtn'
              onClick={() => {
                onClick && onClick();
                // if (!isKYCCompleted) {
                //   setIsShowKYCModal(true);
                // } else {
                //   navigate(Pages.PAYMENT);
                // }
              }}
            />
          )}
        </div>
      </Grid>
    </Wrapper>
  );
};

export default PayButton;
