import Drawer from '@mui/material/Drawer';

const MuiDrawer: React.FC<{
  open: boolean;
  hideDrawer: Function;
  width?: number | string;
  height?: number | string;
  anchor?: 'left' | 'right' | 'top' | 'bottom' | undefined;
  transitionDuration?: number;
  borderRadius?: string | undefined;
}> = ({ open, hideDrawer, children, width, height , anchor='right', transitionDuration, borderRadius}) => {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={() => hideDrawer()}
      // transitionDuration={transitionDuration}
      sx={{
        '& .MuiPaper-root': {
          width: width ? width : 'inherit',
          height: height ? height : '100%',
          borderRadius: borderRadius ? borderRadius : '',
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default MuiDrawer;
