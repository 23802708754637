import { ReactNode } from 'react';
const variantClasses = {
  h1: 'heading1generic',
  h2: 'heading2generic',
  h3: 'heading3generic',
  h4: 'heading4generic',
  h5: 'heading5generic',
  h6: 'heading6generic',
  h7: 'heading7generic',
  body1: 'text-14px',
  body2: 'text-12px',
};

interface Props {
  children?: ReactNode;
  className?: string;
  variant?: string;
  as?: string;
}
const Text = ({ children, className, variant = 'body1', as, ...restProps }: Props) => {
  const Component = as || 'span';
  return (
    // @ts-ignore
    <Component className={`${className} ${variantClasses[variant]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
