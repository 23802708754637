import styled from 'styled-components';

import chat from 'assets/svg/contact-chat.svg';
import email from 'assets/svg/contact-email.svg';
import expertChat from 'assets/svg/contact-expertChat.svg';
import { Grid } from '@mui/material';
import React from 'react';
import { ContactUsActions } from '.';
import parse from 'html-react-parser';

const ActionCardWrapper = styled(Grid)`
  cursor: pointer;
  padding: 20px;
  margin-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(97, 94, 87, 0.14);

  .icon {
    height: 20px;
    width: 20px;
    object-fit: contain;
  }

  .title_holder {
    padding-left: 5px;
    position: relative;
    top: -3px;

    .title {
      color: #1d2939;
    }

    .mini_description {
      margin-top: 5px;
      font-size: 14px;
      color: #231b00;
    }
  }
`;

type ActionItems = {
  type: ContactUsActions;
  icon: string;
  title: string;
  miniDescription: string;
  price?: string;
};

const ContactActionCards = ({ setCurrentAction }: { setCurrentAction: Function }) => {
  const actionItems: ActionItems[] = [
    {
      type: ContactUsActions.CHAT,
      icon: chat,
      title: 'Chat now',
      miniDescription: `
        <p className='mini_description'>
          Chat with our Help Desk by clicking on the chat icon at the bottom right ↘️
        </p>
      `,
    },
    {
      type: ContactUsActions.EMAIL,
      icon: email,
      title: 'Email Us',
      miniDescription: `
        <p className='mini_description'>
          Email Us at <a href='mailto:helpdesk@getyellow.in'>helpdesk@getyellow.in</a>
        </p>
      `,
    },
    {
      type: ContactUsActions.TALK_TO_EXPERT,
      icon: expertChat,
      title: 'Talk to an expert',
      miniDescription: `
        <p className='mini_description'>Download the Yellow App to book your appointment</p>
      `,
      // price: '₹ 199',
    },
  ];
  return (
    <>
      {React.Children.toArray(
        actionItems.map((action) => (
          <ActionCardWrapper
            display={'flex'}
            //  onClick={() => setCurrentAction(action.type)}
          >
            <Grid item lg={1}>
              <img src={action.icon} alt='' className='icon' />
            </Grid>
            <Grid item lg={10} className='title_holder'>
              <p className='title font-semi-bold'>{action.title}</p>
              {parse(action.miniDescription)}
            </Grid>
            {action.price && (
              <Grid item lg={2} sx={{ textAlign: 'center' }}>
                <p className='subText font-semi-bold'>{action.price}</p>
              </Grid>
            )}
          </ActionCardWrapper>
        )),
      )}
    </>
  );
};

export default ContactActionCards;
